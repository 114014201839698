import { combineReducers } from 'redux';
import configReducer from './configReducer';
import contactReducer from './contactReducer';
import homeReducer from './homeReducer';
import fieldReducer from './fieldReducer';
import submitReducer from './submitReducer';
import currentFieldReducer from './currentFieldReducer';
import errorsReducer from './errorsReducer';
import fileUploadReducer from './fileUploadReducer';
import saveReducer from './saveReducer';

import { CLEAR_STORE } from '../constants/actionTypes';
import pdfReducer from './pdfReducer';

const appReducer = combineReducers({
	config: configReducer,
	contact: contactReducer,
	home: homeReducer,
	fields: fieldReducer,
	files: fileUploadReducer,
	currentField: currentFieldReducer,
	submit: submitReducer,
	errors: errorsReducer,
	postSaveData: saveReducer,
	pdf: pdfReducer,
});

const rootReducer = (state, action) => {
	if (action.type === CLEAR_STORE) {
		state = undefined;
	}
	return appReducer(state, action);
};

export default rootReducer;
