import React from 'react';
import { connect } from 'react-redux';
import { submitClaimForm, saveClaim, generatePDF } from '../actions/submitActions';
import { clearState } from '../actions/clearStateAction';
import { sendEmails } from '../actions/emailActions';
import SubmitClaim from '../components/SubmitClaim/SubmitClaim';

const SubmitContainer = props =>
    <SubmitClaim {...props} />

const mapStateToProps = state => ({
    state,
    errors: state.errors
})

const mapDispatchToProps = {
    submitClaimForm,
    generatePDF,
    saveClaim,
    sendEmails,
    clearState,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubmitContainer);