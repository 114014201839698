import {
	UPDATE_CONTACT_INFO,
	UPDATE_DISTRIBUTOR,
} from '../constants/actionTypes';

const initalState = {
	distributor: {},
	product: [],
	usertype: 'Farmer',
	claimtype: '',
	f_firstname: '',
	f_lastname: '',
	f_farmname: '',
	f_payableto: '',
	f_address1: '',
	f_address2: '',
	f_address3: '',
	f_county: '',
	f_postcode: '',
	f_email: '',
	f_phone: '',
	f_selfadvised: false,
	f_includeagronomist: false,
	f_addressConfirm: false,
	a_firstname: '',
	a_lastname: '',
	a_businessname: '',
	a_payableto: '',
	a_address1: '',
	a_address2: '',
	a_county: '',
	a_postcode: '',
	a_email: '',
	a_phone: '',
	ac_firstname: '',
	ac_lastname: '',
	ac_email: '',
	ac_phone: '',
	ac_reference: '',
};

const contactReducer = (state = initalState, action) => {
	switch (action.type) {
		case UPDATE_CONTACT_INFO:
			return action.data;
		case UPDATE_DISTRIBUTOR:
			state.distributor = action.dist;
			return state;
		default:
			return state;
	}
};

export default contactReducer;
