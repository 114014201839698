import React, { Component } from 'react';
import './FieldInformation.scss';
import store from '../../store';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import SubHeaderContainer from '../../containers/SubHeaderContainer';
import { fieldValidation } from '../../ErrorHandlers/fieldValidation';
import Footer from '../Footer/Footer';
import Map from '../Map/Map';
import FileUploadContainer from '../../containers/FileUploadContainer';
import ErrorMsgPanel from '../../components/ErrorMsgPanel/ErrorMsgPanel';
import {
	formatMoney,
	dateTimeToDate,
	dateToDateTime,
} from '../../constants/utils.js';
import { relativeTimeThreshold } from 'moment';

class TooltipContentTemplate extends React.Component {
	render() {
		return (
			<span className='modified-tooltip-text'>{this.props.title}</span>
		);
	}
}

class FieldInformation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props.field,
			files: [],
			drillingDate:
				props.field.drillingDate === ''
					? dateTimeToDate(new Date(2023, 7, 1))
					: props.field.drillingDate,
			applicationDate:
				props.field.applicationDate === ''
					? dateTimeToDate(new Date(2023, 7, 1))
					: props.field.applicationDate,
			failureReasons: [],
			herbicideList: [],
			redrillingOptions: [],
			removalMethods: [],
			estimatedClaim: props.field.estimatedClaim,
			seedRateUnits: [],
			fieldIndex: props.currentField,
			claimtype: store.getState().home,
			searchPostal:
				props.field.searchPostal === ''
					? store.getState().contact.f_postcode
					: props.field.searchPostal,
		};
	}

	componentDidMount() {
		this.initialUpdate();
		window.scrollTo(0, 0);

		const config = { ...this.props.config };
		const failureReasons = config.reasons_failed.map((item) => item.Label);
		const productOptions = config.products.map((item) => item.Name);
		const redrillingOptions = config.redrilling_options
			.map((item) => (item.redrillingTypeID === 1 ? item.Label : ''))
			.filter(Boolean);
		const removalMethods = config.redrilling_options
			.map((item) => (item.redrillingTypeID === 2 ? item.Label : ''))
			.filter(Boolean);
		this.setState({
			failureReasons,
			redrillingOptions,
			removalMethods,
			productOptions,
			productCost: store.getState().home === 'Seed' ? 0 : '',
			doseRate: store.getState().home === 'Seed' ? 0 : '',
		});

		const seedRateUnits = [];
		config.seedrateunit_types.forEach((item) => {
			seedRateUnits.push(item.SeedRateUnit);
		});
		this.setState({ seedRateUnits }, () => {
			if (store.getState().home === 'Seed') {
				this.setState({ seedRateUnit: seedRateUnits[0] });
			}
		});
	}

	componentDidUpdate = () => {
		const products = store.getState().contact.product;
		if (products.length === 1 && this.state.seedType === '') {
			this.setState({ seedType: products[0] });
		}
	};

	sleep = (ms) => {
		return new Promise((resolve) => setTimeout(resolve, ms));
	};

	initialUpdate = async () => {
		const tryUpdate = async () => {
			try {
				if (
					this.props.field.latitude === '' &&
					this.props.field.longitude === ''
				)
					await this.getZipLatLng();
				initialUpdate = true;
			} catch (err) {}
		};
		let initialUpdate = false;
		while (!initialUpdate) {
			this.setState(
				{
					searchPostal:
						this.props.field.searchPostal === ''
							? store.getState().contact.f_postcode
							: this.props.field.searchPostal,
				},
				tryUpdate
			);
			await this.sleep(100);
		}
	};

	updateFieldStore = () => {
		this.props.updateFieldInfo({
			...this.state,
			files: store.getState().fields[this.props.currentField].files,
		});
	};

	getZipLatLng = async () => {
		await geocodeByAddress(this.state.searchPostal + ', UK').then(
			async (results) => {
				await getLatLng(results[0]).then((coords) => {
					this.setState({
						latitude: coords.lat,
						longitude: coords.lng,
					});
				});
			}
		);
	};

	handleMarkerDrag = (latLng) => {
		this.setState({ latitude: latLng.lat(), longitude: latLng.lng() });
	};

	handleChange = (event) => {
		const target = event.target;
		const value =
			target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		if (Array.isArray(value)) {
			this.setState({ [name]: [...value] });
		} else if (name === 'drillingDate' || name === 'applicationDate') {
			this.setState({ [name]: dateTimeToDate(value) });
		} else if (
			name === 'doseRate' ||
			name === 'productCost' ||
			name === 'failedArea'
		) {
			if (name === 'failedArea') {
				const re = /^\d*(\.\d{0,2})?$/;
				if (value === '' || re.test(value)) {
					this.setState({
						failedHa: value === '' ? '' : parseFloat(value),
					});
				}
			} else {
				const re = /^\d*(\.\d{0,2})?$/;
				if (re.test(value)) {
					this.setState({ [name]: parseFloat(value) });
				}
			}
		} else {
			this.setState({ [name]: value });
		}
	};

	handleLatLngInput = (event) => {
		const value = parseFloat(event.target.value);
		const name = event.target.name;

		this.setState({ [name]: value });
	};

	handleRedrilling = (redrilling) => {
		this.setState({
			redrilling,
			redrillCrop: 'Select Re-drilled Crop *',
			removalMethod: 'Select Removal Method *',
		});
	};

	addField = () => {
		this.updateFieldStore();
		if (
			fieldValidation({ ...this.state, files: this.updateFiles() })
				.length === 0
		) {
			this.props.updateCurrentField(this.props.fields.length);
			this.props.addFieldInfo();
			this.props.history.push(
				`/fieldContainer${this.props.fields.length + 1}`
			);
		}
	};

	removeField = () => {
		this.props.deleteFieldErrors({
			fieldCount: store.getState().fields.length,
			index: this.props.currentField,
		});
		this.props.updateCurrentField(this.props.currentField - 1);
		this.props.removeFieldInfo(this.props.currentField);
		this.props.history.push(`/fieldContainer${this.props.currentField}`);
	};

	updateFiles = () => {
		let files = store
			.getState()
			.files.filter((f) =>
				this.props.fields[this.props.currentField].files.includes(f.ID)
			);
		return files.map((f) => {
			return { id: f.ID, type: f.documentType };
		});
	};

	handleFocus = () => {
		if (this.state.seedRate === 0 || this.state.seedRate === '0') {
			this.setState({ seedRate: '' });
		}
	};

	handleBlur = (e) => {
		const { name } = e.target;
		if (name === 'seedRate' && this.state.seedRate === '') {
			this.setState({ seedRate: '0' });
		}
		if (
			name === 'failedArea' ||
			name === 'doseRate' ||
			name === 'productCost'
		) {
			let product =
				(this.state.productCost !== ''
					? parseFloat(this.state.productCost)
					: 0) *
				(this.state.doseRate !== ''
					? parseFloat(this.state.doseRate)
					: 0.0);
			let area =
				this.state.failedArea !== ''
					? parseFloat(this.state.failedHa)
					: 0;
			this.setState({
				estimatedClaim: parseFloat(
					(product > 50 ? 50 : product) * parseFloat(area)
				),
			});
		}
	};

	handleKeyDown = (e) => {
		if (
			(e.key.length === 1 && e.key !== '.' && isNaN(e.key)) ||
			(e.key === '.' && e.target.value.toString().indexOf('.') > -1)
		) {
			e.preventDefault();
		}
	};

	render() {
		const isLastField =
			this.props.currentField + 1 === this.props.fields.length;
		const isFirstField = this.props.currentField === 0;
		const minDate = new Date(2023, 7, 1);
		const maxSeedDate = new Date(2023, 8, 22);
		const maxHerbDate = new Date(2023, 9, 31);
		const selectedDrillDate = new Date(this.state.drillingDate);
		const selectedAppDate = new Date(this.state.applicationDate);
		let drillDateError = false;
		let appDateError = false;

		if (
			selectedDrillDate.getUTCFullYear() < minDate.getUTCFullYear() ||
			selectedDrillDate.getUTCFullYear() > maxSeedDate.getUTCFullYear()
		) {
			drillDateError = true;
		} else if (
			selectedDrillDate.getUTCMonth() < minDate.getUTCMonth() ||
			selectedDrillDate.getUTCMonth() > maxSeedDate.getUTCMonth()
		) {
			drillDateError = true;
		} else if (
			selectedDrillDate.getUTCMonth() === maxSeedDate.getUTCMonth() &&
			selectedDrillDate.getUTCDate() > maxSeedDate.getUTCDate()
		) {
			drillDateError = true;
		}

		if (
			selectedAppDate.getUTCFullYear() < minDate.getUTCFullYear() ||
			selectedAppDate.getUTCFullYear() > maxHerbDate.getUTCFullYear()
		) {
			appDateError = true;
		} else if (
			selectedAppDate.getUTCMonth() < minDate.getUTCMonth() ||
			selectedAppDate.getUTCMonth() > maxHerbDate.getUTCMonth()
		) {
			appDateError = true;
		}

		return (
			<React.Fragment>
				<div className='fieldInfoContainer'>
					<SubHeaderContainer />
					<div className='input'>
						<Input
							name='fieldname'
							value={this.state.fieldname}
							label='Field Name *'
							onChange={this.handleChange}
							maxLength={30}
							className={`required-field ${
								!this.state.fieldname ? 'error' : 'good'
							}`}
							// required={true}
						/>
						<p style={{ fontSize: '11px', marginBottom: '0px' }}>
							To add any additional fields, please select ‘add new
							field’ at the bottom of the page.
						</p>
					</div>
					<h2>Field Details</h2>
					<div className='header-with-icon'>
						<p className='header-text'>
							Please provide the exact location for the claim
							field
						</p>
						<Tooltip
							content={(props) => (
								<TooltipContentTemplate title={props.title} />
							)}
							position='top'
							anchorElement='target'
							className='header-tooltip'
						>
							<span
								className='header-icon'
								title='To move the map, click and hold the left mouse button and drag the map to a new place. Click on the centre of the field to drop the pin or click and drag the pin'
							>
								i
							</span>
						</Tooltip>
					</div>
					<ul>
						<li>
							Enter the postcode, area or GPS coordinates of the
							field
						</li>
						<li>Then place a pin in the centre of the field</li>
					</ul>
					<div className='searchContainer'>
						<Input
							className='searchPostal'
							name='searchPostal'
							value={this.state.searchPostal}
							label='Enter your postcode/area to search'
							onChange={this.handleChange}
							required={false}
						/>
						<button
							className='searchBtn'
							onClick={this.getZipLatLng}
						>
							Search
						</button>
					</div>

					<Map
						handleMarkerDrag={this.handleMarkerDrag}
						latitude={this.state.latitude}
						longitude={this.state.longitude}
					/>

					<div className='inputContainer inputBorder'>
						<div className='input'>
							<Input
								name='latitude'
								value={this.state.latitude}
								label='Latitude *'
								onChange={this.handleLatLngInput}
								required={true}
								type='number'
								step='any'
								min={-90}
								max={90}
							/>
						</div>
						<div className='input'>
							<Input
								name='longitude'
								value={this.state.longitude}
								label='Longitude *'
								onChange={this.handleLatLngInput}
								required={true}
								type='number'
								step='any'
								min={-180}
								max={180}
							/>
						</div>
					</div>

					<div className='inputContainer'>
						<div className='input'>
							<Input
								name='failedArea'
								value={this.state.failedHa}
								label='Failed Hectares *'
								onChange={this.handleChange}
								onKeyDown={this.handleKeyDown}
								onBlur={this.handleBlur}
								required={true}
								type='number'
								pattern='^\d*(\.\d{0,2})?$'
								className={`required-field ${
									!this.state.failedHa ? 'error' : 'good'
								}`}
								min={3}
								step={0.01}
							/>
							<div className='required'>Minimum of 3 ha</div>
						</div>
					</div>

					<div className='dropDownTextContainer'>
						<MultiSelect
							data={this.state.failureReasons}
							value={this.state.failureReason}
							onChange={this.handleChange}
							name='failureReason'
							label='Reason for Failure *'
							className={`required-field ${
								this.state.failureReason.length === 0
									? 'error'
									: 'good'
							}`}
						/>
					</div>

					{this.state.failureReason.includes('Other') ? (
						<div className='input'>
							<Input
								name='otherFailureReason'
								value={this.state.otherFailureReason}
								label='Other Reason'
								onChange={this.handleChange}
								required={true}
								className={`required-field ${
									!this.state.otherFailureReason
										? 'error'
										: 'good'
								}`}
							/>
						</div>
					) : (
						''
					)}

					{store.getState().contact.product !== 'Select Product *' ? (
						<div className='productHerbicide'>
							{store.getState().home === 'Herbicide' ? (
								<div
									className={`productNameText ${
										!this.state.seedType ? 'error' : 'good'
									}`}
								>
									Herbicide *
								</div>
							) : (
								<div
									className={`productNameText ${
										!this.state.seedType ? 'error' : 'good'
									}`}
								>
									Seed Type *
								</div>
							)}
							<DropDownList
								data={store.getState().contact.product}
								value={this.state.seedType}
								onChange={this.handleChange}
								name='seedType'
								required={true}
								className={`required-field ${
									!this.state.seedType ? 'error' : 'good'
								}`}
							/>
						</div>
					) : (
						<div className='productHerbicide'>
							<div className='noProductText'>
								{' '}
								*No product selected (choose a product on the
								Contact Information page)*
							</div>
						</div>
					)}

					<div className='datePickerContainer'>
						<div className='datePickerInput'>
							<div
								className={`drillText ${
									drillDateError ? 'error' : 'good'
								}`}
							>
								Drilling Date *
							</div>
							<DatePicker
								format={'yyyy-MMM-dd'}
								name='drillingDate'
								onChange={this.handleChange}
								required={true}
								className={`datePicker required-field ${
									drillDateError ? 'error' : 'good'
								}`}
								min={minDate}
								max={maxSeedDate}
								value={
									dateToDateTime(this.state.drillingDate) ||
									new Date(2023, 7, 1)
								}
							/>
						</div>

						{store.getState().home === 'Herbicide' ? (
							<div className='datePickerInput'>
								<div
									className={`drillText ${
										appDateError ? 'error' : 'good'
									}`}
								>
									Application Date *
								</div>
								<DatePicker
									format={'yyyy-MMM-dd'}
									name='applicationDate'
									onChange={this.handleChange}
									required={true}
									className={`datePicker required-field ${
										appDateError ? 'error' : 'good'
									}`}
									min={minDate}
									max={maxHerbDate}
									value={
										dateToDateTime(
											this.state.applicationDate
										) || new Date(2023, 7, 1)
									}
								/>
							</div>
						) : (
							''
						)}
					</div>

					{store.getState().home === 'Herbicide' ? (
						<div className='inputContainer'>
							<div className='input'>
								<Input
									name='seedVariety'
									value={this.state.seedVariety}
									label='Seed Variety *'
									onChange={this.handleChange}
									required={true}
									className={`required-field ${
										!this.state.seedVariety
											? 'error'
											: 'good'
									}`}
								/>
							</div>
							<div className='input inputWithUnits'>
								<Input
									name='seedRate'
									value={this.state.seedRate}
									label='Seed Rate*'
									type='number'
									onChange={this.handleChange}
									onFocus={this.handleFocus}
									onBlur={this.handleBlur}
									required={true}
									min={0}
									className={`required-field ${
										!this.state.seedRate ||
										this.state.seedRate === '0'
											? 'error'
											: 'good'
									}`}
								/>
								<DropDownList
									data={this.state.seedRateUnits}
									defaultValue={'Unit *'}
									value={this.state.seedRateUnit}
									onChange={this.handleChange}
									name='seedRateUnit'
									className={`required-field ${
										!this.state.seedRateUnit
											? 'error'
											: 'good'
									}`}
								/>
							</div>
						</div>
					) : (
						''
					)}

					{store.getState().home === 'Seed' ? (
						<div className='input inputWithUnits'>
							<Input
								name='seedRate'
								value={this.state.seedRate}
								label='Seed Rate*'
								type='number'
								onChange={this.handleChange}
								onFocus={this.handleFocus}
								onBlur={this.handleBlur}
								required={true}
								className={`required-field ${
									!this.state.seedRate ||
									this.state.seedRate === '0'
										? 'error'
										: 'good'
								}`}
							/>
							{this.state.seedRateUnits.length > 1 ? (
								<DropDownList
									data={this.state.seedRateUnits}
									defaultValue={'Unit *'}
									value={this.state.seedRateUnit}
									onChange={this.handleChange}
									name='seedRateUnit'
									required={true}
									className={`required-field ${
										!this.state.seedRateUnit
											? 'error'
											: 'good'
									}`}
								/>
							) : (
								<div className='seedRateUnit'>
									{this.state.seedRateUnit}
								</div>
							)}
						</div>
					) : (
						''
					)}

					<h2>Supporting Documents</h2>
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>
						<div className='documentContainer'>
							<div className='attachContainer'>
								<div className='attachText'>
									Purchase Invoice *
								</div>
								<FileUploadContainer documentType='Purchase Invoice' />
							</div>
						</div>
						<div className='documentContainer'>
							<div className='attachContainer'>
								<div className='attachText'>
									Agronomist Recommendation
								</div>
								<FileUploadContainer documentType='Agronomist Recommendation' />
							</div>
						</div>
					</div>
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>
						<div className='documentContainer'>
							<div className='attachContainer'>
								<div className='attachText'>
									{this.props.home === 'Herbicide'
										? 'Application Records *'
										: 'Drilling Records'}
								</div>
								<FileUploadContainer
									documentType={
										this.props.home === 'Herbicide'
											? 'Application Records'
											: 'Drilling Records'
									}
								/>
							</div>
						</div>
						<div className='documentContainer'>
							<div className='attachContainer'>
								<div className='attachText'>
									Pictures of Claim Field
								</div>
								<FileUploadContainer documentType='Pictures of Claim Field' />
							</div>
						</div>
					</div>
					{this.state.claimtype === 'Seed' &&
						this.props.contact.distributor === 'Agrii' &&
						this.props.contact.product !== 'InV1035 Premium' && (
							<p style={{ fontSize: 13 }}>
								*Payments for products purchased through Agrii
								will be administered by the Distributor.
							</p>
						)}
					{this.state.claimtype === 'Seed' &&
						this.props.contact.distributor === 'Agrii' &&
						this.props.contact.product === 'InV1035 Premium' && (
							<p style={{ fontSize: 13 }}>
								*Payments and/or vouchers for products purchased
								through Agrii will be administered by the
								Distributor.
							</p>
						)}
					{store.getState().home === 'Herbicide' ? (
						<React.Fragment>
							<h2>Claim Calculator</h2>
							<div className='inputContainer claimCalculator'>
								<div className='input'>
									<Input
										name='failedArea'
										value={this.state.failedHa}
										type='number'
										label='Failed Area(ha) *'
										onChange={this.handleChange}
										onKeyDown={this.handleKeyDown}
										onBlur={this.handleBlur}
										pattern='^\d*(\.\d{0,2})?$'
										required={true}
										min={0}
										step={0.01}
									/>
								</div>
								<div className='input'>
									<Input
										name='productCost'
										value={this.state.productCost}
										type='number'
										label='Product Cost Per Litre (£/l) *'
										onChange={this.handleChange}
										onKeyDown={this.handleKeyDown}
										onBlur={this.handleBlur}
										pattern='^\d*(\.\d{0,2})?$'
										required={true}
										min={0}
										step={0.01}
									/>
								</div>
								<div className='input'>
									<Input
										name='doseRate'
										value={this.state.doseRate}
										label='Dose Rate (l/ha) *'
										type='number'
										onChange={this.handleChange}
										onKeyDown={this.handleKeyDown}
										onBlur={this.handleBlur}
										pattern='^\d*(\.\d{0,2})?$'
										required={true}
										min={0}
										step={0.01}
									/>
								</div>
							</div>

							<p className='estimatedClaim'>
								Estimated Claim:{' '}
								<span style={{ color: '#f19425' }}>
									{isNaN(this.state.estimatedClaim)
										? '£0.00'
										: formatMoney(
												this.state.estimatedClaim
										  )}
								</span>
							</p>
							{this.state.doseRate * this.state.productCost >
							50 ? (
								<p>
									The maximum claim value possible is £50 per
									ha
								</p>
							) : (
								''
							)}

							{this.props.contact.distributor === 'Agrii' &&
								this.props.contact.product !==
									'InV1035 Premium' && (
									<p style={{ fontSize: 13 }}>
										*Payments for products purchased through
										Agrii will be administered by the
										Distributor.
									</p>
								)}
						</React.Fragment>
					) : (
						''
					)}

					<h2>Re-Drilling Details</h2>
					<div className='redrillingContainer'>
						<div
							className={
								this.state.redrilling === 'redrillingField'
									? 'redrilling active'
									: 'redrilling'
							}
							onClick={() =>
								this.handleRedrilling('redrillingField')
							}
						>
							Re-drilling your field
						</div>
						<div
							className={
								this.state.redrilling === 'leavingField'
									? 'redrilling active'
									: 'redrilling'
							}
							onClick={() =>
								this.handleRedrilling('leavingField')
							}
						>
							Leaving it Fallow
						</div>
					</div>

					{this.state.redrilling === 'redrillingField' ? (
						<React.Fragment>
							<DropDownList
								data={this.state.redrillingOptions}
								defaultValue={'Select Re-drilled Crop *'}
								value={this.state.redrillCrop}
								onChange={this.handleChange}
								name='redrillCrop'
								className={`required-field ${
									!this.state.redrillCrop ? 'error' : 'good'
								}`}
							/>
							{this.state.redrillCrop === 'Other' ? (
								<div className='input'>
									<Input
										name='redrilingOther'
										value={this.state.redrilingOther}
										label='Crop'
										onChange={this.handleChange}
										required={true}
									/>
								</div>
							) : (
								''
							)}
							<p style={{ fontSize: '13px' }}>
								*Please ensure that any remaining Winter OSR
								plants are removed from the field as this could
								affect your claim.
							</p>
						</React.Fragment>
					) : (
						<React.Fragment>
							<DropDownList
								data={this.state.removalMethods}
								defaultValue={'Select Removal Method *'}
								value={this.state.removalMethod}
								onChange={this.handleChange}
								name='removalMethod'
							/>
						</React.Fragment>
					)}

					<h2>Do you want to add another Field ?</h2>

					<div>
						<div className='addNewFieldBtn' onClick={this.addField}>
							Add New Field
						</div>
						{this.props.currentField > 0 && (
							<div
								className='removeFieldBtn'
								onClick={this.removeField}
							>
								Remove Field
							</div>
						)}
					</div>
				</div>

				{this.props.errors.errors &&
				this.props.errors.errors['Field Details'][
					this.state.fieldIndex
				] ? (
					<ErrorMsgPanel
						errors={
							this.props.errors.errors['Field Details'][
								this.state.fieldIndex
							]
						}
					/>
				) : null}

				<Footer
					validate={() =>
						fieldValidation({
							...this.state,
							files: this.updateFiles(),
						})
					}
					onClick={this.updateFieldStore}
					fieldPage={true}
					nextRoute={
						isLastField
							? '/submitClaim'
							: `/fieldContainer${this.props.currentField + 2}`
					}
					backRoute={
						isFirstField
							? '/contactContainer'
							: `/fieldContainer${this.props.currentField}`
					}
					value={'Next'}
				/>
			</React.Fragment>
		);
	}
}

export default FieldInformation;
