import React from 'react';
export const fieldValidation = (event) => {
	let fieldErrors = [];
	fieldErrors.push(
		validateEmptyString('Field Details: Field Name', event.fieldname)
	);
	fieldErrors.push(
		validateNumber('Field Details: Latitude', event.latitude, false)
	);
	fieldErrors.push(
		validateNumber('Field Details: Longitude', event.longitude, false)
	);
	fieldErrors.push(
		validateHectares('Field Details: Failed Hectares', event.failedHa)
	);
	fieldErrors.push(
		validateEmptyArray(
			'Field Details: Reason for Failure',
			event.failureReason
		)
	);
	fieldErrors.push(
		validateEmptyString('Field Details: Seed Rate Unit', event.seedRateUnit)
	);
	fieldErrors.push(validateSeedDate('Drilling Date: ', event.drillingDate));
	if (event.claimtype === 'Herbicide') {
		fieldErrors.push(
			validateHerbDate('Application Date: ', event.applicationDate)
		);
	}
	if (event.failureReason.includes('Other')) {
		fieldErrors.push(
			validateEmptyString(
				'Field Details: Reason for Failure (Other)',
				event.otherFailureReason
			)
		);
	}
	if (event.redrillCrop === 'Other') {
		fieldErrors.push(
			validateEmptyString(
				'Re-Drilling Details: Re-drill Crop (Other)',
				event.redrilingOther
			)
		);
	}

	if (event.claimtype === 'Herbicide') {
		fieldErrors.push(
			validateEmptyString(
				'Field Details: Seed Variety',
				event.seedVariety
			)
		);
		fieldErrors.push(
			validateNumber(
				'Claim Calculator: Product Cost per ha',
				event.productCost,
				true
			)
		);
		fieldErrors.push(
			validateNumber('Claim Calculator: Dose Rate', event.doseRate, true)
		);
	} else {
		fieldErrors.push(
			validateSeedRate('Field Details: Seed Rate', event.seedRate)
		);
		fieldErrors.push(
			validateEmptyString(
				'Field Details: Seed Rate Unit',
				event.seedRateUnit
			)
		);
	}
	event.redrilling === 'redrillingField'
		? fieldErrors.push(
				validateEmptyString(
					'Re-Drilling Details: Re-drill Crop',
					event.redrillCrop
				)
		  )
		: fieldErrors.push(
				validateEmptyString(
					'Re-Drilling Details: Removal Method',
					event.removalMethod
				)
		  );

	fieldErrors.push(validateFiles('Purchase Invoice', event.files));
	if (event.claimtype === 'Herbicide') {
		fieldErrors.push(validateFiles('Application Records', event.files));
	}
	/*if (event.claimtype === 'Seed') {
		fieldErrors.push(validateFiles('Drilling Records', event.files));
	}*/

	return fieldErrors.filter((d) => d);
};

const validateNumber = (name, num, nonzero) => {
	if (num === undefined || num === '') {
		return `${name} is a required field.`;
	} else if (isNaN(num)) {
		return `${name} is not a valid number.`;
	} else if (nonzero && num <= 0) {
		return `${name} must be greater than zero.`;
	}
};

const validateEmptyString = (name, string) => {
	if (
		!string ||
		string === '' ||
		string === 'Select reason for failure' ||
		string === 'Select Re-drilled Crop *' ||
		string === 'Select Removal Method *'
	) {
		if (name === 'seedRateUnit')
			return 'Seed Rate Unit is a required field.';
		if (name === 'redrillCrop') return 'Re-drill Crop is a required field.';
		return `${name} is a required field.`;
	}
};

const validateEmptyArray = (name, array) => {
	if (!array || array.length === 0) {
		return `${name} is a required field.`;
	}
};

const validateHectares = (name, failedArea) => {
	if (!failedArea || failedArea === '') {
		return `${name} is a required field.`;
	} else if (failedArea < 3) {
		return `${name} must be minimum of 3 ha.`;
	}
};

const validateSeedRate = (name, seedRate) => {
	if (!seedRate || seedRate === '') {
		return `${name} is a required field.`;
	} else if (seedRate < 40) {
		return (
			<React.Fragment>
				{`${name} The minimum to qualify for this programme is 40 seeds/m`}
				<sup>2</sup>
			</React.Fragment>
		);
	}
};

const validateFiles = (name, files) => {
	if (files.find((f) => f.type === name) === undefined) {
		return `You must upload your ${name}.`;
	}
};

const validateSeedDate = (name, drillDate) => {
	const minDate = new Date(2023, 7, 1);
	const maxSeedDate = new Date(2023, 8, 22);
	const selectedDrillDate = new Date(drillDate);

	if (
		selectedDrillDate.getUTCFullYear() < minDate.getUTCFullYear() ||
		selectedDrillDate.getUTCFullYear() > maxSeedDate.getUTCFullYear()
	) {
		return `${name} Does not fall within the valid date range.`;
	} else if (
		selectedDrillDate.getUTCMonth() < minDate.getUTCMonth() ||
		selectedDrillDate.getUTCMonth() > maxSeedDate.getUTCMonth()
	) {
		return `${name} Does not fall within the valid date range.`;
	} else if (
		selectedDrillDate.getUTCMonth() === maxSeedDate.getUTCMonth() &&
		selectedDrillDate.getUTCDate() > maxSeedDate.getUTCDate()
	) {
		return `${name} Does not fall within the valid date range.`;
	}
};

const validateHerbDate = (name, appDate) => {
	const minDate = new Date(2023, 7, 1);
	const maxHerbDate = new Date(2023, 9, 31);
	const selectedAppDate = new Date(appDate);

	if (
		selectedAppDate.getUTCFullYear() < minDate.getUTCFullYear() ||
		selectedAppDate.getUTCFullYear() > maxHerbDate.getUTCFullYear()
	) {
		return `${name} Does not fall within the valid date range.`;
	} else if (
		selectedAppDate.getUTCMonth() < minDate.getUTCMonth() ||
		selectedAppDate.getUTCMonth() > maxHerbDate.getUTCMonth()
	) {
		return `${name} Does not fall within the valid date range.`;
	}
};
