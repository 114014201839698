import { UPDATE_FIELD_INFO, ADD_FIELD_INFO, SEND_FILES, DELETE_FILE, DELETE_FIELD_INFO } from '../constants/actionTypes';
import { fieldInformationModel } from '../constants/fieldInformationModel';
import { union } from 'lodash';

const initialState = [fieldInformationModel];

const updateFieldInfo = (state, action) => {
	return state.map((f, i) => {
		if (action.data.fieldIndex !== i) {
			return f;
		}
		return { ...action.data }
	});
}

const removeField = (state, action) => {
	return state.slice(0, action.data).concat(state.slice(action.data+1,state.length));
}

const sendFiles = (state, action) => {
	// Update the array of fileIDs in specific field  
	const fields = JSON.parse(JSON.stringify(state))
	const field = fields[action.currentFieldID]
    const newFileIDs = action.files.map(f => f.ID)
	const files = union([...field.files, ...newFileIDs])
	field.files = files;
	return fields
}

const deleteFile = (state, action) => {
	const fields = JSON.parse(JSON.stringify(state))
	const field = fields[action.currentFieldID]
	const files = field.files
		.filter(ID => ID !== action.fileID)
	field.files = files
	return fields
}

const addField = (state, action) => {
    return [...state, {...action.data, files: []}];
}

const fieldReducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_FIELD_INFO: return updateFieldInfo(state, action);
		case ADD_FIELD_INFO: return addField(state, action);
		case DELETE_FIELD_INFO: return removeField(state, action);
		case SEND_FILES: return sendFiles(state, action);
		case DELETE_FILE: return deleteFile(state, action);
		default: return state;
	}
}

export default fieldReducer;