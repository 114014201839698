import { SEND_FILES, DELETE_FILE } from '../constants/actionTypes';
import { uniqBy } from 'lodash';

const sendFiles = (state, action) => {
    action.files.map(f => f.documentType = action.documentType);
    // action.files may contain files already in the store
    return uniqBy([...state, ...action.files], "ID");
}

const deleteFile = (state, action) => {
    return [...state.filter(file => file.ID !== action.fileID)]
}

const fieldReducer = (state = [], action) => {
    switch (action.type) {
        case SEND_FILES: return sendFiles(state, action);
        case DELETE_FILE: return deleteFile(state, action);
        default: return state;
    }
}

export default fieldReducer;