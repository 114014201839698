import { SUBMIT_CLAIM_FORM, SAVE_CLAIM_FORM } from '../constants/actionTypes';
import axios from 'axios';
import { baseURL } from '../constants/api';

export function submitClaimForm(data) {
    return { 
        type: SUBMIT_CLAIM_FORM,
        data: data
    }
}

export function saveClaim(data) {
    return async (dispatch) => {
            let url = baseURL + '/save';
            let postData = data || {};
            try {
                let response = await axios.post(url, postData);
                delete response.data.status;
                dispatch(
                    {
                        type: SAVE_CLAIM_FORM,
                        data: response.data
                    }
                );
                return response.data;
            } 
            catch(error) {
                return {'error': 'There was an error saving claim data.'}
            }
    }
} 

export function generatePDF(data) {
	return async (dispatch) => {
		let url = baseURL + '/pdf';
		let postData = data || {};
		try {
        	let response = await axios.post(url, postData);
        	return response.data;
		} catch(error) {
			return {'error': 'There was an error generating pdf.'}
		}
	}
}

