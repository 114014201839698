export const fieldInformationModel = {
	searchPostal: '',
	latitude: '',
	longitude: '',
	drillingDate: '',
	failedHa: '',
	failureReason: [],
	otherFailureReason: '',
	product: '',
	productCost: '',
	estimatedClaim: 0,
	doseRate: '',
	redrilling: 'redrillingField',
	redrilingOther: '',
	applicationDate: '',
	seedVariety: '',
	seedRate: '0',
	seedRateUnit: '',
	seedType: '',
	files: [],
	fieldname: '',
};
