import React from 'react';
import { connect } from 'react-redux';
import SuccessPage from '../components/SuccessPage/SuccessPage';
import { clearState } from '../actions/clearStateAction';
import { updateRiskShare } from '../actions/homeActions';

const SuccessContainer = (props) => {
	return <SuccessPage {...props} />;
};

const mapStateToProps = (state) => ({
	entireState: state,
});

const mapDispatchToProps = (dispatch) => ({
	clearState: () => dispatch(clearState()),
	updateRiskShare: (data) => dispatch(updateRiskShare(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuccessContainer);
