import React from 'react';
import { Provider } from 'react-redux';
import App from './App';
import { MemoryRouter } from 'react-router-dom';
import { homepage } from '../constants/api';

const Root = ({ store }) =>
    <Provider store={store}>
        <MemoryRouter basename={homepage}>
            <App />
        </MemoryRouter>
    </Provider>

export default Root;