import axios from 'axios';
import { baseURL } from '../constants/api';
import { SEND_FILES, DELETE_FILE } from '../constants/actionTypes';


function deleteFile(fileID, currentFieldID) {
    return {
        type: DELETE_FILE,
        fileID,
        currentFieldID
    }
}

function sendFiles(files, currentFieldID, documentType) {
    return {
        type: SEND_FILES,
        files,
        currentFieldID,
        documentType
    }
}

const buildFileFormData = (fileObj, documentType) => {
    const data = new FormData();
    data.append('documentType', documentType)
    Array.from(fileObj).forEach(file => {
        data.append('files', file);
    });
    return data;
}

export function doDeleteFile(fileID, currentFieldID) {
    return async dispatch => {
        // Remove the tmp fileData from the server
        try {
            let url = `${baseURL}/deletefiles`;
            await axios.post(url, { ID: fileID });
            return dispatch(deleteFile(fileID, currentFieldID)); // Delete the file from the store
        }
        catch (err) {
            throw new Error(err)
        }
    }
}

export function doSendFiles(currentFieldID, files, documentType) {
    return async dispatch => {
        try {
            let formData = await buildFileFormData(files, documentType);
            let url = `${baseURL}/files`;
            let res = await axios.post(url, formData);
            return dispatch(sendFiles(res.data.files, currentFieldID, documentType));
        }
        catch (err) {
            throw new Error(err)
        }
    }
}
