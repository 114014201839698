import { UPDATE_RISK_SHARE } from '../constants/actionTypes';

const initalState = ''

const homeReducer = (state = initalState, action) => {
    switch (action.type) {
        case UPDATE_RISK_SHARE: return action.data;
        default: return state;
    }
}

export default homeReducer;