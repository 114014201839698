import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import './ContactInformation.scss';
import store from '../../store';
import { connect } from 'react-redux';
import SubHeaderContainer from '../../containers/SubHeaderContainer';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import Footer from '../../components/Footer/Footer';
import CheckBox from '../../components/CheckBox/CheckBox';
import { contactValidation } from '../../ErrorHandlers/contactValidation';
import ErrorMsgPanel from '../../components/ErrorMsgPanel/ErrorMsgPanel';

class ContactInformation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props.contact,
			distributors: [],
			productList: [],
			autocomplete: null,
			usertype:
				props.contact.distributor.Name === 'Agrii'
					? 'AgriiContact'
					: 'Farmer',
		};
		// this.farmbusiness = React.createRef();
		// this.agrobusiness = React.createRef();
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		let products = [];
		let product = {};
		if (this.state.product.length === 0) {
			if (
				this.props.home === 'Seed' &&
				this.props.config &&
				this.state.productList.length === 0
			) {
				products = this.selectProductList(this.props.distributor.Name);
				this.setState({
					productList: products,
					product: products.length > 0 ? [] : '',
				});
			}

			//After Nov 4, only 2020 Inv10355 Premium claim allowed
			/*if (this.props.home === 'Seed' && this.props.distributor.Name == "Agrii") {
				this.setState({
					productList: ['2020 InV1035 Premium'],
				});
			}*/

			if (this.props.home === 'Seed' && this.props.distributor.Name === "Agrii" && !window.location.pathname.includes("/premium")) {
				this.setState({
					productList: products.filter(p => !p.includes('Premium'))
				});
			}



			if (
				this.props.home === 'Herbicide' &&
				this.state.product.length === 0
			) {
				this.setState({
					product: ['Kat Turbo'],
				});
			}
		}
		this.farmcomplete = new window.google.maps.places.Autocomplete(
			document.getElementById('farmlookup'),
			{ componentRestrictions: { country: ['gb'] } }
		);

		this.farmcomplete.addListener('place_changed', this.handleFarmSelect);

		// this.farmpostal = new window.google.maps.places.Autocomplete(
		// 	document.getElementById('farmpostal'),
		// 	{ type: 'address', componentRestrictions: { country: ['gb'] } }
		// );

		// this.farmpostal.addListener(
		// 	'place_changed',
		// 	this.handleFarmPostalSelect
		// );

		// this.agrocomplete = new window.google.maps.places.Autocomplete(
		// 	document.getElementById('agrolookup'),
		// 	{ componentRestrictions: { country: ['gb'] } }
		// );

		// this.agrocomplete.addListener('place_changed', this.handleAgroSelect);

		// this.agropostal = new window.google.maps.places.Autocomplete(
		// 	document.getElementById('agropostal'),
		// 	{ type: 'address', componentRestrictions: { country: ['gb'] } }
		// );

		// this.agropostal.addListener(
		// 	'place_changed',
		// 	this.handleAgroPostalSelect
		// );
	}

	componentDidUpdate(prevProps, prevState) {
		// if (
		// 	prevState.config !== this.props.config &&
		// 	this.props.config.isLoaded
		// ) {
		// 	const config = { ...this.props.config };
		// 	if (config && config.distributors && config.distributors.length) {
		// 		const distributors = config.distributors.map(
		// 			(item) => item.Name
		// 		);
		// 		this.setState({ distributors });
		// 	}
		// }
		if (
			prevState.usertype !== this.state.usertype ||
			prevState.f_selfadvised !== this.state.f_selfadvised
		) {
			this.farmcomplete = null;
			this.agrocomplete = null;
			this.farmpostal = null;
			this.agropostal = null;
			this.farmcomplete = new window.google.maps.places.Autocomplete(
				document.getElementById('farmlookup'),
				{ componentRestrictions: { country: ['gb'] } }
			);
			this.farmcomplete.addListener(
				'place_changed',
				this.handleFarmSelect
			);

			// this.farmpostal = new window.google.maps.places.Autocomplete(
			// 	document.getElementById('farmpostal'),
			// 	{
			// 		type: 'address',
			// 		componentRestrictions: { country: ['gb'] },
			// 	}
			// );

			// this.farmpostal.addListener(
			// 	'place_changed',
			// 	this.handleFarmPostalSelect
			// );

			// this.agrocomplete = new window.google.maps.places.Autocomplete(
			// 	document.getElementById('agrolookup'),
			// 	{ componentRestrictions: { country: ['gb'] } }
			// );
			// this.agrocomplete.addListener(
			// 	'place_changed',
			// 	this.handleAgroSelect
			// );

			// this.agropostal = new window.google.maps.places.Autocomplete(
			// 	document.getElementById('agropostal'),
			// 	{
			// 		type: 'address',
			// 		componentRestrictions: { country: ['gb'] },
			// 	}
			// );

			// this.agropostal.addListener(
			// 	'place_changed',
			// 	this.handleAgroPostalSelect
			// );
		}
		if (
			this.props.home === 'Seed' &&
			this.state.productList.length === 1 &&
			this.state.product.length === 0
		) {
			const prod = [];
			prod.push(this.state.productList[0]);
			this.setState({ product: prod });
		}
		if (this.state.productList.length === 0) {
			if (this.props.home === 'Seed') {
				const products = this.selectProductList(
					this.props.distributor.Name
				);
				this.setState({
					productList: products,
				});
			}
			//After Nov 4, only 2020 Inv10355 Premium claim allowed
			/*if (this.props.home === 'Seed' && this.props.distributor.Name == "Agrii") {
				this.setState({
					productList: ['2020 InV1035 Premium'],
				});
			}*/


			if (
				this.props.home === 'Herbicide' &&
				this.state.product.legnth === 0
			) {
				this.setState({
					product: ['Kat Turbo'],
				});
			}
		}
	}

	updateContactStore = () => {
		this.props.updateContactInfo({
			...this.state,
			claimtype: this.props.home,
		});
	};

	selectProductList = (distributorName) => {
		const config = this.props.config;
		// const distributorList = [...config.distributors];
		let distributor = this.props.contact.distributor;
		let product = config.products.filter((p) =>
			distributor.products.includes(p.productID)
		);
		return product.map((item) => item.Name);
	};

	handleChange = (event) => {
		const target = event.target;
		const value =
			target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		if (target.name === undefined && target.value === undefined) {
			this.setState({
				[target.attributes.name.value]: target.attributes.value.value,
			});
		} else {
			if (name === 'f_farmname')
				this.setState({ [name]: value, f_payableto: value });
			else if (
				name === 'product' &&
				this.state.productList.length === 1
			) {
				const prod = [];
				prod.push(value);
				this.setState({ [name]: prod });
			} else if (
				name === 'f_phone' ||
				name === 'a_phone' ||
				name === 'ac_phone'
			) {
				const re = /^[0-9\s]*$/;
				if (value === '' || re.test(value)) {
					this.setState({
						[name]: value === '' ? '' : value,
					});
				}
			} else this.setState({ [name]: value });
		}

		// if (target.props !== undefined && target.props.name === 'distributor') {
		// 	this.setState({
		// 		productList: this.selectProductList(value),
		// 		product: 'Select Product *',
		// 	});
		// }
	};

	inputField = (name, placeholder, required, id, disable, val) => (
		<div className='input'>
			{name === 'farmLookup' ||
			name === 'f_address3' ||
			name === 'a_businessname' ||
			name === 'a_phone' ||
			name === 'ac_phone' ||
			name === 'ac_reference' ? (
				<Input
					name={name}
					value={val ? val : this.state[name]}
					label={`${placeholder}${required ? ' *' : ''}`}
					onChange={this.handleChange}
					required={required}
					disabled={disable ? disable : false}
					id={id}
				/>
			) : (
				<Input
					name={name}
					value={val ? val : this.state[name]}
					label={`${placeholder}${required ? ' *' : ''}`}
					onChange={this.handleChange}
					required={required}
					disabled={disable ? disable : false}
					className={`required-field ${
						!this.state[name] ? 'error' : 'good'
					}`}
					id={id}
				/>
			)}
			{name === 'f_phone' || name === 'a_phone' || name === 'ac_phone' ? (
				<p className='phone-examples'>
					e.g. 020 1111 222, 07111 222222
				</p>
			) : null}
			{name === 'f_payableto' ? (
				<p className='phone-examples'>
					*Business Account Name is required
				</p>
			) : null}
		</div>
	);

	handleFarmSelect = () => {
		let addressObject = this.farmcomplete.getPlace();
		let address = addressObject.address_components;

		let f_address1 = '';
		let f_address2 = '';
		let f_address3 = '';
		let f_farmname = '';
		let f_county = '';
		let f_postcode = '';

		address.map((item, ind) => {
			if (item.types.findIndex((x) => x === 'premise') > -1) {
				f_farmname = item.long_name;
			}
			if (item.types.findIndex((x) => x === 'street_number') > -1) {
				f_address1 += item.long_name;
			}
			if (item.types.findIndex((x) => x === 'route') > -1) {
				f_address1 += ` ${item.long_name}`;
			}
			if (item.types.findIndex((x) => x === 'locality') > -1) {
				f_address3 = item.long_name;
			}
			if (item.types.findIndex((x) => x === 'postal_town') > -1) {
				f_address2 = item.long_name;
			}
			if (
				item.types.findIndex(
					(x) => x === 'administrative_area_level_2'
				) > -1
			) {
				f_county = item.long_name;
			}
			if (item.types.findIndex((x) => x === 'postal_code') > -1) {
				f_postcode = item.long_name;
			}
		});
		if (f_farmname === '')
			f_farmname = addressObject.name
				? `${addressObject.formatted_address}`
				: this.state.f_farmname;
		this.setState({
			// f_farmname,
			f_address1,
			f_address2,
			f_address3,
			f_county,
			f_postcode,
			farmLookup: '',
			// f_payableto:
			// this.state.distributor.Name !== 'Agrii' ? f_farmname : '',
		});
	};

	// handleFarmPostalSelect = () => {
	// 	let addressObject = this.farmpostal.getPlace();
	// 	let address = addressObject.address_components;
	// 	let f_address1 = '';
	// 	let f_address2 = '';
	// 	let f_farmname = '';
	// 	let f_county = '';
	// 	let f_postcode = '';

	// 	address.map((item, ind) => {
	// 		if (item.types.findIndex((x) => x === 'premise') > -1) {
	// 			f_farmname = item.long_name;
	// 		}
	// 		if (item.types.findIndex((x) => x === 'street_number') > -1) {
	// 			f_address1 = item.long_name;
	// 		}
	// 		if (item.types.findIndex((x) => x === 'route') > -1) {
	// 			f_address1 += ` ${item.long_name}`;
	// 		}
	// 		if (item.types.findIndex((x) => x === 'locality') > -1) {
	// 			f_address1 += ` ${item.long_name}`;
	// 		}
	// 		if (item.types.findIndex((x) => x === 'postal_town') > -1) {
	// 			f_address2 = item.long_name;
	// 		}
	// 		if (
	// 			item.types.findIndex(
	// 				(x) => x === 'administrative_area_level_2'
	// 			) > -1
	// 		) {
	// 			f_county = item.long_name;
	// 		}
	// 		if (item.types.findIndex((x) => x === 'postal_code') > -1) {
	// 			f_postcode = item.long_name;
	// 		}
	// 	});
	// 	if (f_farmname === '')
	// 		f_farmname = addressObject.name
	// 			? `${addressObject.name} - ${addressObject.formatted_address}`
	// 			: this.state.f_farmname;
	// 	this.setState({
	// 		// f_farmname,
	// 		f_address1,
	// 		f_address2,
	// 		f_county,
	// 		f_postcode,
	// 		farmLookup: f_farmname,
	// 		// f_payableto:
	// 		// this.state.distributor.Name !== 'Agrii' ? f_farmname : '',
	// 	});
	// };

	// handleAgroSelect = (e) => {
	// 	let addressObject = this.agrocomplete.getPlace();
	// 	let address = addressObject.address_components;

	// 	let a_address1 = '';
	// 	let a_address2 = '';
	// 	let a_businessname = '';
	// 	let a_county = '';
	// 	let a_postcode = '';

	// 	address.map((item, ind) => {
	// 		if (item.types.findIndex((x) => x === 'premise') > -1) {
	// 			a_businessname = item.long_name;
	// 		}
	// 		if (item.types.findIndex((x) => x === 'street_number') > -1) {
	// 			a_address1 = item.long_name;
	// 		}
	// 		if (item.types.findIndex((x) => x === 'route') > -1) {
	// 			a_address1 += ` ${item.long_name}`;
	// 		}
	// 		if (item.types.findIndex((x) => x === 'locality') > -1) {
	// 			a_address1 += ` ${item.long_name}`;
	// 		}
	// 		if (item.types.findIndex((x) => x === 'postal_town') > -1) {
	// 			a_address2 = item.long_name;
	// 		}
	// 		if (
	// 			item.types.findIndex(
	// 				(x) => x === 'administrative_area_level_2'
	// 			) > -1
	// 		) {
	// 			a_county = item.long_name;
	// 		}
	// 		if (item.types.findIndex((x) => x === 'postal_code') > -1) {
	// 			a_postcode = item.long_name;
	// 		}
	// 	});
	// 	if (a_businessname === '')
	// 		a_businessname = addressObject.name
	// 			? `${addressObject.name} - ${addressObject.formatted_address}`
	// 			: this.state.a_businessname;
	// 	this.setState({
	// 		// a_businessname,
	// 		a_address1,
	// 		a_address2,
	// 		a_county,
	// 		a_postcode,
	// 		agroLookup: a_businessname,
	// 	});
	// };

	// handleAgroPostalSelect = (e) => {
	// 	let addressObject = this.agropostal.getPlace();
	// 	let address = addressObject.address_components;

	// 	let a_address1 = '';
	// 	let a_address2 = '';
	// 	let a_businessname = '';
	// 	let a_county = '';
	// 	let a_postcode = '';

	// 	address.map((item, ind) => {
	// 		if (item.types.findIndex((x) => x === 'premise') > -1) {
	// 			a_businessname = item.long_name;
	// 		}
	// 		if (item.types.findIndex((x) => x === 'street_number') > -1) {
	// 			a_address1 = item.long_name;
	// 		}
	// 		if (item.types.findIndex((x) => x === 'route') > -1) {
	// 			a_address1 += ` ${item.long_name}`;
	// 		}
	// 		if (item.types.findIndex((x) => x === 'locality') > -1) {
	// 			a_address1 += ` ${item.long_name}`;
	// 		}
	// 		if (item.types.findIndex((x) => x === 'postal_town') > -1) {
	// 			a_address2 = item.long_name;
	// 		}
	// 		if (
	// 			item.types.findIndex(
	// 				(x) => x === 'administrative_area_level_2'
	// 			) > -1
	// 		) {
	// 			a_county = item.long_name;
	// 		}
	// 		if (item.types.findIndex((x) => x === 'postal_code') > -1) {
	// 			a_postcode = item.long_name;
	// 		}
	// 	});
	// 	if (a_businessname === '')
	// 		a_businessname = addressObject.name
	// 			? `${addressObject.name} - ${addressObject.formatted_address}`
	// 			: this.state.a_businessname;
	// 	this.setState({
	// 		// a_businessname,
	// 		a_address1,
	// 		a_address2,
	// 		a_county,
	// 		a_postcode,
	// 		agroLookup: a_businessname,
	// 	});
	// };

	render() {
		const FarmerContactForm = (
			<div>
				<h2>Farmer Details</h2>
				{(this.state.usertype === 'Agronomist' ||
					this.state.usertype === 'AgriiContact') && (
					<p>A copy of this claim will be sent to your farmer.</p>
				)}
				<div className='inputContainer'>
					{this.inputField('f_firstname', 'First Name', true)}
					{this.inputField('f_lastname', 'Last Name', true)}
				</div>
				<div className='inputContainer'>
					{this.inputField('f_farmname', 'Farm Business Name', true)}
					{this.state.distributor.Name !== 'Agrii' &&
						this.inputField(
							'f_payableto',
							'Cheque Payable To',
							true
						)}
				</div>
				<div className='inputContainer'>
					{this.inputField(
						'farmLookup',
						'Address Lookup',
						false,
						'farmlookup'
					)}
					{this.inputField('f_email', 'Email', true)}
				</div>
				<div className='inputContainer'>
					{this.inputField('f_address1', 'House Name / Number', true)}
					{this.inputField('f_phone', 'Phone Number', true)}
				</div>
				<div className='inputContainer'>
					{this.inputField('f_address3', 'Address Line 2', false)}
				</div>
				<div className='inputContainer'>
					{this.inputField('f_address2', 'Town', true)}
				</div>
				<div className='inputContainer'>
					{this.inputField('f_county', 'County', true)}
				</div>
				<div className='inputContainer'>
					{this.inputField(
						'f_postcode',
						'Postcode',
						true,
						'farmpostal'
					)}
				</div>
				<div
					className='checkBoxContainer'
					style={{ marginTop: '20px', marginBottom: '20px' }}
				>
					<CheckBox
						name='f_addressConfirm'
						handleChecked={this.handleChange}
						text={`${
							this.state.distributor.Name === 'Agrii'
								? 'I agree the address details are correct.'
								: 'I agree the address details above are correct and include the house name or number for written correspondence.'
						}`}
						checked={this.state.f_addressConfirm}
					/>
					{!this.state.f_selfadvised && AgronomistContactForm}
				</div>
			</div>
		);

		const AgronomistContactForm = (
			<div>
				{(this.state.usertype === 'Agronomist' ||
					this.state.usertype === 'AgriiContact') && (
					<h2>Agronomist Details</h2>
				)}
				{this.state.usertype === 'Farmer' && (
					<p>
						Please include your agronomist's contact information; a
						copy of your claim will be sent.
					</p>
				)}
				<div className='inputContainer'>
					{this.inputField('a_firstname', 'First Name', true)}
					{this.inputField('a_lastname', 'Last Name', true)}
				</div>
				<div className='inputContainer'>
					{this.inputField('a_businessname', 'Business Name', false)}
					{/* {this.state.usertype === 'Agronomist' &&
						this.state.distributor === 'Hutchinsons' &&
						this.inputField(
							'a_distributorrefnum',
							'Distributor Reference Number',
							false
						)} */}
				</div>
				{/* <div className='inputContainer'>
					{this.inputField(
						'agroLookup',
						'Address Lookup',
						false,
						'agrolookup'
					)}
				</div> */}
				{/* <div className='inputContainer'>
					{this.inputField(
						'a_postcode',
						'Postcode',
						true,
						'agropostal'
					)}
					{this.inputField(
						'a_address1',
						'Address Line 1',
						true,
						'agrobusiness'
					)}
				</div>
				<div className='inputContainer'>
					{this.inputField('a_address2', 'Address Line 2', false)}
					{this.inputField('a_county', 'County', true)}
				</div> */}
				<div className='inputContainer'>
					{this.inputField('a_email', 'Email', true)}
					{this.inputField('a_phone', 'Phone Number', false)}
				</div>
			</div>
		);

		const AgriiContactForm = (
			<div>
				<h2>Agrii Contact Details</h2>
				<div className='inputContainer'>
					{this.inputField('ac_firstname', 'First Name', true)}
					{this.inputField('ac_lastname', 'Last Name', true)}
				</div>
				<div className='inputContainer'>
					{this.inputField('ac_email', 'Email', true)}
					{this.inputField('ac_phone', 'Phone Number', false)}
				</div>
				<div className='inputContainer'>
					{this.inputField(
						'ac_reference',
						'Agrii Reference Number',
						false
					)}
				</div>
			</div>
		);

		// const includeAgronomistCheck = (
		// 	<React.Fragment>
		// 		<h2>Agronomist Details</h2>
		// 		<div className='checkBoxContainer'>
		// 			<CheckBox
		// 				name='f_includeagronomist'
		// 				handleChecked={this.handleChange}
		// 				text='I am Self advised.'
		// 				checked={this.state.f_includeagronomist}
		// 			/>
		// 			{!this.state.f_includeagronomist && AgronomistContactForm}
		// 		</div>
		// 	</React.Fragment>
		// );

		const selfAdvisedCheck = (
			<React.Fragment>
				{this.state.usertype === 'Farmer' && (
					<h2>Agronomist Details</h2>
				)}
				<div className='checkBoxContainer'>
					<CheckBox
						name='f_selfadvised'
						handleChecked={this.handleChange}
						text='I am Self-advised.'
						checked={this.state.f_selfadvised}
					/>
					{!this.state.f_selfadvised && AgronomistContactForm}
				</div>
			</React.Fragment>
		);

		const IAmSelector = (
			<React.Fragment>
				<h2>I am</h2>
				<div className='iAmContainer'>
					<div
						name='usertype'
						className={
							this.state.usertype === 'Farmer'
								? 'iAm active'
								: 'iAm'
						}
						value='Farmer'
						onClick={this.handleChange}
					>
						a Farmer
					</div>
					<div
						name='usertype'
						className={
							this.state.usertype === 'Agronomist'
								? 'iAm active'
								: 'iAm'
						}
						value='Agronomist'
						onClick={this.handleChange}
					>
						an Agronomist
					</div>
					{this.props.contact.distributor.Name === 'Agrii' && (
						<div
							name='usertype'
							className={
								this.state.usertype === 'AgriiContact'
									? 'iAm active'
									: 'iAm'
							}
							value='AgriiContact'
							onClick={this.handleChange}
						>
							an Agrii Contact
						</div>
					)}
				</div>
			</React.Fragment>
		);
		return (
			<React.Fragment>
				{/* <Script url={googleMapURL} onLoad={this.handleScriptLoad} /> */}
				<div className='contactContainer'>
					<p className='no-btm-margin contact-title'>
						Contact Information
					</p>
					<div className='inputContainer'>
						<div className='input'>
							<span className='k-text-box-container'>
								{this.inputField(
									`${this.props.distributor.Name}`,
									'Seed Seller / Distributor',
									true,
									'distributor',
									true,
									this.props.distributor.Name
								)}
							</span>
						</div>
					</div>
					<div className='productContainer'>
						{this.props.home === 'Herbicide' ? (
							<React.Fragment>
								{this.inputField(
									'herbicide',
									'Herbicide',
									true,
									'herbicide',
									true,
									'Kat Turbo'
								)}
							</React.Fragment>
						) : (
							<React.Fragment>
								<p className='no-btm-margin'>
									Select seed purchased
								</p>
								{this.state.productList.length > 1 ? (
									<MultiSelect
										id='clear'
										data={this.state.productList}
										value={this.state.product}
										onChange={this.handleChange}
										name='product'
									/>
								) : this.state.productList.length === 1 ? (
									<DropDownList
										id='clear'
										data={this.state.productList}
										value={this.state.product}
										onChange={this.handleChange}
										name='product'
										// listNoDataRender={() => 'PLEASE SELECT A DISTRIBUTOR'}
									/>
								) : null}
							</React.Fragment>
						)}
					</div>
					{IAmSelector}
					{this.state.usertype === 'Farmer' && FarmerContactForm}
					{this.state.usertype === 'Farmer' && selfAdvisedCheck}
					{this.state.usertype === 'Agronomist' &&
						AgronomistContactForm}
					{this.state.usertype === 'Agronomist' && FarmerContactForm}
					{this.state.usertype === 'AgriiContact' && AgriiContactForm}
					{this.state.usertype === 'AgriiContact' &&
						FarmerContactForm}
					{this.state.usertype === 'AgriiContact' &&
						AgronomistContactForm}
				</div>

				{this.props.errors.errors &&
				this.props.errors.errors['Contact Details'] ? (
					<ErrorMsgPanel
						errors={this.props.errors.errors['Contact Details']}
					/>
				) : null}

				<Footer
					validate={() => contactValidation(this.state)}
					onClick={this.updateContactStore}
					nextRoute={'/fieldContainer1'}
					backRoute={''}
					value={'Next'}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		home: state.home,
		distributor: state.contact.distributor,
		config: state.config,
		contact: state.contact,
	};
};

export default connect(mapStateToProps)(ContactInformation);
