import React, { useState } from 'react';
import { connect } from 'react-redux';
import { updateRiskShare } from '../../actions/homeActions';
import { updateCurrentField } from '../../actions/fieldActions';
import { NavLink, withRouter } from 'react-router-dom';
import Modal from '../Modal/Modal';
import './Footer.scss';

const Footer = (props) => {
	const [showModal, toggleModal] = useState(false);

	const changeField = (route) => {
		const fieldNum = /\/fieldContainer([0-9]+)/.exec(route);
		if (fieldNum !== null) {
			props.updateCurrentField(parseInt(fieldNum[1]) - 1);
		}
	};

	return (
		<div className='footer'>
			{showModal ? (
				<Modal
					title='Cancel Claim'
					text='Are you sure you wish to cancel your claim? Your changes will not be saved.'
					confirm={() => {
						toggleModal(false);
						props.history.push('/');
						props.updateRiskShare('');
					}}
					cancel={() => toggleModal(false)}
				/>
			) : null}
			<div className='footer-link left'>
				<div
					className='button cancel'
					onClick={() => toggleModal(true)}
				>
					Cancel
				</div>
			</div>
			{props.validate().length === 0 ? (
				<NavLink
					className='footer-link right'
					exact
					to={props.nextRoute}
				>
					<div
						className='button next'
						onClick={() => {
							props.onClick();
							changeField(props.nextRoute);
						}}
					>
						{props.value}
					</div>
				</NavLink>
			) : (
				<div className='button next' onClick={props.onClick}>
					{props.value}
				</div>
			)}
			{props.backRoute !== '' && (
				<NavLink
					className='footer-link right'
					exact
					to={props.backRoute}
				>
					<div
						className='button back'
						onClick={() => {
							if (props.fieldPage) props.onClick();
							changeField(props.backRoute);
						}}
					>
						Back
					</div>
				</NavLink>
			)}
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	updateRiskShare: (data) => dispatch(updateRiskShare(data)),
	updateCurrentField: (data) => dispatch(updateCurrentField(data)),
});

export default withRouter(connect(null, mapDispatchToProps)(Footer));
