import { UPDATE_ERRORS, REMOVE_VALIDATION_ERRORS, CLAIM_VALIDATION_ERROR, DELETE_FIELD_ERRORS } from '../constants/actionTypes';

export const doUpdateErrors = errors => ({
    type: UPDATE_ERRORS,
    payload: errors
});

export const deleteFieldErrors = data => ({
    type: DELETE_FIELD_ERRORS,
    payload: data
})

export const doRemoveValidationErrors = parent => ({
    type: REMOVE_VALIDATION_ERRORS,
    payload: parent
})

export const claimValidationError = errors => ({
	type: CLAIM_VALIDATION_ERROR,
	errors
})