import moment from 'moment';
import _ from 'lodash';

export const formatMoney = (number) => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'GBP',
		minimumFractionDigits: 2,
	});
	return formatter.format(number);
};

export const dateTimeToDate = (date) => {
	let currentDate = date;
	if (date === null) {
		currentDate = new Date();
	}
	return `${currentDate.getFullYear()}-${
		currentDate.getMonth() + 1 < 10 ? '0' : ''
	}${currentDate.getMonth() + 1}-${
		currentDate.getDate() < 10 ? '0' : ''
	}${currentDate.getDate()}`;
};

export const dateToDateTime = (date) => {
	let currentDate = date;
	if (date === null) {
		return new Date();
	} else if (typeof date === 'object') {
		return new Date(date);
	}
	let dateArray = currentDate.split('-');
	let newDate = moment()
		.year(parseInt(dateArray[0]))
		.month(parseInt(dateArray[1]) - 1)
		.date(parseInt(dateArray[2]))
		.toDate();
	return newDate;
};

// export const updateObject = (oldObject, newValues) => {
// 	return { ...oldObject, ...newValues };
// };

// export const createReducer = (initialState, handlers) => {
// 	return (state = initialState, action) => {
// 		if (_.has(handlers, action.type)) {
// 			return handlers[action.type](state, action);
// 		} else {
// 			return state;
// 		}
// 	};
// };
