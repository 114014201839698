import React from 'react';
import './SubHeader.scss';

const SubHeader = (props) => {
	if (props.home === '') {
		return (
			<React.Fragment>
				<div className='subHeader'>
					<div className='container'>
						<h1 className='orange scroll justify-center'>
							BASF OSR Establishment Risk Share Programme
						</h1>
					</div>
				</div>
			</React.Fragment>
		);
	} else {
		return (
			<div className='subHeader'>
				{props.home === 'Seed' ? (
					<h1 className='orange'>Seed Risk Share Claim</h1>
				) : (
					''
				)}
				{props.home === 'Herbicide' ? (
					<h1 className='orange'>Herbicide Risk Share Claim</h1>
				) : (
					''
				)}
			</div>
		);
	}
};

export default SubHeader;
