import React, { Component } from 'react';
import './SuccessPage.scss';
import { NavLink } from 'react-router-dom';
import { formatMoney } from '../../constants/utils.js';

class SuccessPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			date: `${new Date().getDate()}/${
				new Date().getMonth() + 1
			}/${new Date().getFullYear()}  
                    ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
		};
	}

	componentDidMount() {}

	render() {
		const { entireState } = this.props;

		let displayFieldInformation = () => {
			let information = [];
			entireState.fields.forEach(function (field, index) {
				let fieldLabel = '';
				fieldLabel =
					field.fieldname !== ''
						? field.fieldname
						: index === 0
						? 'Field Information'
						: 'Field Information ' + (index + 1).toString();
				information.push(
					<div key={index} className='submit-p'>
						<div className='text'>
							{fieldLabel} - Estimated Claim Value:
						</div>
						<div className='answer'>
							{formatMoney(field.estimatedClaim)}
						</div>
					</div>
				);
			});
			return information;
		};

		return (
			<React.Fragment>
				<div className='successContainer'>
					<h1>Claim form submitted successfully!</h1>
					<p>
						Thank you, you have successfully submitted your claim
						for the BASF plc Winter OSR Establishment Risk Share
						Programme. Your claim will be assessed and processed by
						BASF, if you have any questions about your claim please
						contact:{' '}
						<a
							href='mailto: customer-support-uk@agconnect.org'
							target='_blank'
							className='success-support-email'
						>
							customer-support-uk@agconnect.org
						</a>{' '}
						and reference the following number:{' '}
						{entireState.postSaveData.referenceNumber}
					</p>

					<div className='submit-p-container'>
						<div className='submit-p'>
							<div className='text'>Date of submission:</div>
							<div className='answer'>{this.state.date}</div>
						</div>
						<div className='submit-p'>
							<div className='text'>Reference Number:</div>
							<div className='answer'>
								{entireState.postSaveData.referenceNumber}
							</div>
						</div>
						<div className='submit-p'>
							<div className='text'>Programme:</div>
							<div className='answer'>{entireState.home}</div>
						</div>
						<div className='submit-p'>
							<div className='text'>Product Purchased From:</div>
							<div className='answer'>
								{entireState.contact.distributor.Name}
							</div>
						</div>
						<div className='submit-p'>
							<div className='text'>Product:</div>
							<div className='answer'>
								{Array.isArray(entireState.contact.product)
									? entireState.contact.product.map(
											(item) => (
												<p className='product'>
													{item}
												</p>
											)
									  )
									: entireState.contact.product}
							</div>
						</div>
						<div className='submit-p'>
							<div className='text'>Farm Name:</div>
							<div className='answer'>
								{entireState.contact.f_farmname}
							</div>
						</div>

						{entireState.home === 'Herbicide'
							? displayFieldInformation()
							: ''}
					</div>

					<p>
						Click below to download a detailed version of your claim
						along with the applicable terms and conditions for that
						program.
					</p>
					<a
						href={this.props.entireState.pdf.link}
						target='_blank'
						rel='noopener noreferrer'
					>
						<div className='printPDFContainer'>
							<div className='greenArrow'></div>
							<div className='greenText'>Download a PDF</div>
						</div>
					</a>

					<NavLink exact to='/'>
						<div
							className='proccessBtn'
							onClick={() => {
								this.props.clearState();
								this.props.updateRiskShare('');
							}}
						>
							Submit Another Claim
						</div>
					</NavLink>
				</div>
			</React.Fragment>
		);
	}
}

export default SuccessPage;
