import axios from 'axios';
import { baseURL } from '../constants/api';
import {
	REQUEST_CONFIG_DATA,
	RECIEVE_CONFIG_DATA,
} from '../constants/actionTypes';
import store from '../store';

function requestConfig() {
	return {
		type: REQUEST_CONFIG_DATA,
	};
}

function recieveConfig(json) {
	return {
		type: RECIEVE_CONFIG_DATA,
		data: json,
	};
}

function getConfig(url, dispatch) {
	return axios.get(url).then((res) => {
		dispatch(recieveConfig(res.data));
	});
}

function shouldFetchConfig(state) {
	const config = state.config;
	return !config.isLoading;
}

export function fetchConfig() {
	return (dispatch) => {
		dispatch(requestConfig());
		let url = `${baseURL}/config?type=${
			store.getState().home === '' || store.getState().home === 'Seed'
				? 'InVigor'
				: store.getState().home
		}`;
		// let url = `${baseURL}/config?type=Herbicide`;
		// let url = `${baseURL}/config?type=InVigor`;
		getConfig(url, dispatch);
	};
}

export function fetchConfigIfNeeded() {
	return (dispatch, getState) => {
		if (shouldFetchConfig(getState())) {
			return dispatch(fetchConfig());
		}
	};
}
