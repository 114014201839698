import {
	UPDATE_CONTACT_INFO,
	UPDATE_FIELD_INFO,
	SUBMIT_CLAIM_FORM,
	UPDATE_RISK_SHARE,
} from '../../constants/actionTypes';
import { validateClaimInputs } from '../../ErrorHandlers/EventHandler.jsx';
import { claimValidationError } from '../../actions/errorsActions';
import { clearState } from '../../actions/clearStateAction';

export const claimInputMiddleware = ({ dispatch }) => (next) => async (
	action
) => {
	let errors;
	// if (action.type === UPDATE_RISK_SHARE) {
	//     // dispatch(clearState());
	// }

	switch (action.type) {
		case UPDATE_CONTACT_INFO:
		case UPDATE_FIELD_INFO:
		case SUBMIT_CLAIM_FORM:
			errors = await validateClaimInputs(getPayload(action), action.type);
			break;
		default:
			return next(action);
	}

	dispatch(claimValidationError(errors));
	next(action);
};

//gets claim data from different actions
function getPayload(action) {
	switch (action.type) {
		case UPDATE_CONTACT_INFO:
		default:
			return action.data;
	}
}
