import React from 'react';
import { connect } from 'react-redux';
import SubHeader from '../components/SubHeader/SubHeader';

const SubHeaderContainer = props =>
    <SubHeader {...props} />

const mapStateToProps = state => ({
    home: state.home,
})

export default connect(
    mapStateToProps
)(SubHeaderContainer);