import React from 'react';
import './Spinner.scss'
import SpinnerImg from './spinner.svg';

const Spinner = () => {
    return (
        <div className='SpinnerContainer'>
            <img className='Spinner' src={SpinnerImg} alt='Loading' />
        </div>
    )
}

export default Spinner;