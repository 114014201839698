import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import store from '../store';
import Header from '../components/Header/Header';
import SubmitContainer from './SubmitContainer';
import ContactContainer from './ContactContainer';
import HomeContainer from './HomeContainer';
import NavBarContainer from '../containers/NavBarContainer';
import FieldRoutesContainer from './FieldRoutesContainer';
import SuccessContainer from '../containers/SuccessContainer';
import Hero from '../components/Hero/Hero';
import HeroImg from '../assets/images/common/hero.jpg';
import '@progress/kendo-theme-default/dist/all.css';


const Routes = () => (
	<Switch>
		<Route exact path={'/'} component={HomeContainer} />
		<Route exact path={'/contactContainer'} component={ContactContainer} />
		<Route path={'/submitClaim'} component={SubmitContainer} />
		<Route path={'/successPage'} component={SuccessContainer} />
	</Switch>
);

const Title = (path) => {
	let titleText;
	const home = useSelector((state) => state.home);
	if (path === '/') {
		titleText = 'Winter OSR Establishment Risk Share Programme';
	}
	if (home === 'Herbicide') {
		titleText = 'Herbicide Risk Share Claim';
	}
	if (home === 'Seed') {
		titleText = 'Seed Risk Share Claim';
	}
	return titleText;
};

const App = (props) => {
	let titleText;
	const home = useSelector((state) => state.home);
	if (props.location.pathname === '/')
		titleText = 'Winter OSR Establishment Risk Share Programme';
	if (home === 'Herbicide') titleText = 'Herbicide Risk Share Claim';
	if (home === 'Seed') titleText = 'Seed Risk Share Claim';
	return (
		<div className='App'>
			{
				<header className='app-header'>
					<div className='container' style={{ width: '100%' }}>
						<div className='bannerLink'></div>
						<div className='app-logo'></div>
					</div>
				</header>
			}
			<div className='pageTitle'>
				<h2>{titleText}</h2>
			</div>
			<div
				className={
					'mainContainer' +
					(props.location.pathname === '/' ? '' : ' page-width')
				}
			>
				<NavBarContainer />
				<div className='rightContainer'>
					<Routes />
					<FieldRoutesContainer />
				</div>
			</div>
		</div>
	);
};

export default withRouter(App);
