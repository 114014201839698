import {
	UPDATE_CONTACT_INFO,
	UPDATE_DISTRIBUTOR,
} from '../constants/actionTypes';

export function updateContactInfo(data) {
	return {
		type: UPDATE_CONTACT_INFO,
		data: data,
	};
}

export function updateDistributor(dist) {
	return {
		type: UPDATE_DISTRIBUTOR,
		dist,
	};
}
