import { UPDATE_CURRENT_FIELD } from '../constants/actionTypes';
const initalState = 0;

const currentFieldReducer = (state = initalState, action) => {
    switch (action.type) {
        case UPDATE_CURRENT_FIELD: return action.data;
        default: return state;
    }
}

export default currentFieldReducer;