import React, {useState, useEffect, useCallback } from 'react';
import { find, union } from 'lodash';
import { useDropzone } from 'react-dropzone';
import ErrorMsgPanel from '../ErrorMsgPanel/ErrorMsgPanel';
import store from '../../store';
import './FileUpload.css';

const acceptedMimeTypes = `
    image/bmp,
    application/msword,
    application/vnd.openxmlformats-officedocument.wordprocessingml.document,
    image/gif,
    image/jpeg,
    image/jpeg,
    application/pdf,
    image/png,
    application/rtf,
    image/svg+xml,
    application/vnd.ms-excel,
    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
`
const maxFileSize = 5242880;

const FileUpload = (props) => {
    const { documentType, currentFieldID, sendFiles, deleteFile } = props;

    let files = store.getState().files.filter(f => store.getState().fields[props.currentFieldID].files.includes(f.ID) && props.documentType === f.documentType);

    const [fileState, setFileState] = useState(files.map(f => { return {...f, documentType: undefined, name: f.originalname}}));

    const [errors, setErrors] = useState([]);

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        fileState.map(file => URL.revokeObjectURL(file.preview));
    }, [fileState]);

    const uploadFiles = async fileObj => {
        await Promise.all(Array.from(fileObj).map(async file => {
            Object.assign(file, { preview: URL.createObjectURL(file) });
        }));
        // sending the files to the server returns an ID
        // Save ID of the fileObj in state to reference on delete
        try {
            let response = await sendFiles(currentFieldID, fileObj, documentType);
            for (let f of response.files) {
                let currentFile = find(fileObj, { 'name': f.originalname })
                currentFile.ID = f.ID;
            }
            setErrors([]);
            setFileState(fileState => union([...fileState, ...fileObj]));
        }
        catch {
            // Error uploading files to server
            setErrors(["There was an issue uploading files. Please try again."])
        }
    }

    const handleDelete = async e => {
        const fileID = e.target.parentElement.getAttribute('file');
        const newFileState = fileState.filter(f => f.ID !== fileID)
        try {
            await deleteFile(fileID, currentFieldID);
            setErrors([]);
            setFileState(newFileState);
        }
        catch {
            // Error uploading files to server
            setErrors(["There was an issue deleting the file. Please try again."])
        }
    }

    const  onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length) {
            uploadFiles(acceptedFiles);
        }
    }, [uploadFiles]);

    const { getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles } = useDropzone({
        accept: acceptedMimeTypes,
        maxSize: maxFileSize,
        onDrop,
        multiple: true,
    });

    const thumbs = Array.from(fileState).map(file => (
        <div className='file' key={file.ID} file={file.ID}>
            <div className='name'>{file.name}</div>
            <div className='delete' onClick={handleDelete}>✖</div>
        </div>
    ));

        const FileUploadError = ({ text }) => (
            <div style={{color: "red"}}>{text}</div>
        )

    return (
        <>
            <ErrorMsgPanel errors={errors} />
            <section className="no-select" styles={{ width: '250px', height: '200px', paddingLeft: '15px', paddingRigt: '15px'}}>
                <div {...getRootProps({ className: 'dropzone' })} style={{'display':'table', 'height':'200px'}}>
                    <input {...getInputProps()} name="files" multiple />
                    {!isDragActive && <div style={{'display':'table-cell', 'verticalAlign':'middle'}}>
                        Drag and drop files here, or click to select files.
                        {isDragReject && <FileUploadError text="File type is not accepted. Please upload an image or PDF." />}
                    {rejectedFiles.length > 0 && rejectedFiles[0].size > maxFileSize && <FileUploadError text="Files must be less than 5MB." />}
                    {rejectedFiles.length > 0 && rejectedFiles[0].size <= maxFileSize && <FileUploadError text="File type is not accepted. Please upload an image or PDF." />}
                    
                    </div>}
                    {isDragActive && !isDragReject && "Drop file to upload."}
                </div>
                <aside className="thumbs-container">
                    {thumbs}
                </aside>
            </section>
        </>
    )
}

export default FileUpload;