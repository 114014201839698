import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import NavBar from '../components/NavBar/NavBar';
import { updateCurrentField } from '../actions/fieldActions';

const NavBarContainer = props =>
    <div className={'leftContainer ' + ((props.home === '') ? 'hidden' : '')}>
        <NavBar {...props} />
    </div>

const mapStateToProps = state => ({
    home: state.home,
    fields: state.fields,
    currentField: state.currentField,
    errors: state.errors
})

const mapDispatchToProps = dispatch => ({
    updateCurrentField: (data) => dispatch(updateCurrentField(data)),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(NavBarContainer));