export const UPDATE_CONTACT_INFO = 'UPDATE_CONTACT_INFO';
export const UPDATE_DISTRIBUTOR = 'UPDATE_DISTRIBUTOR';
export const UPDATE_RISK_SHARE = 'UPDATE_RISK_SHARE';

export const REQUEST_CONFIG_DATA = 'REQUEST_CONFIG_DATA';
export const RECIEVE_CONFIG_DATA = 'RECIEVE_CONFIG_DATA';

export const UPDATE_FIELD_INFO = 'UPDATE_FIELD_INFO';
export const ADD_FIELD_INFO = 'ADD_FIELD_INFO';
export const DELETE_FIELD_INFO = 'DELETE_FIELD_INFO';
export const UPDATE_CURRENT_FIELD = 'UPDATE_CURRENT_FIELD';

export const SUBMIT_CLAIM_FORM = 'SUBMIT_CLAIM_FORM';
export const SAVE_CLAIM_FORM = 'SAVE_CLAIM_FORM';

// File Upload
export const SEND_FILES = 'SEND_FILES';
export const DELETE_FILE = 'DELETE_FILE';

//ERRORS
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const REMOVE_VALIDATION_ERRORS = 'REMOVE_VALIDATION_ERRORS';
export const DELETE_FIELD_ERRORS = 'DELETE_FIELD_ERRORS';
export const CLAIM_VALIDATION_ERROR = 'CLAIM_VALIDATION_ERROR';

//EMAIL
export const SEND_EMAILS = 'SEND_EMAILS';

//CLEAR STORE
export const CLEAR_STORE = 'CLEAR_STORE';
