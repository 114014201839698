import React from 'react';
import "./CheckBox.scss";

const CheckBox = props => {
    return (      
        <label className="checkbox" >
            <input name={props.name} className="checkbox__trigger visuallyhidden" type="checkbox" onChange={props.handleChecked} checked={!!props.checked} />
            <span className="checkbox__symbol">
                <svg aria-hidden="true" className="icon-checkbox" width="28px" height="28px" viewBox="0 0 28 28" version="1" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 14l8 7L24 7"></path>
                </svg>
            </span>
            <p className="checkbox__textwrapper">{props.text}</p>
        </label> 
    ); 
}

export default CheckBox;