import React, { Component } from 'react';
import './SubmitClaim.scss';
import { withRouter } from 'react-router-dom';
import Footer from '../Footer/Footer';
import CheckBox from '../../components/CheckBox/CheckBox';
import ErrorMsgPanel from '../../components/ErrorMsgPanel/ErrorMsgPanel';
import Modal from '../Modal/Modal';
import Spinner from '../Spinner/Spinner.jsx';
import { submitValidation } from '../../ErrorHandlers/submitValidation';

class SubmitClaim extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.props.state.submit,
			showModal: false,
		};
	}

	handleChange = (event) => {
		const target = event.target;
		const value =
			target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({ [name]: value }, () =>
			this.props.submitClaimForm(this.state)
		);
		this.props.submitClaimForm(this.state);
	};

	validateClaim = () => {
		let isValid = true;
		if (
			this.props.errors.errors['Contact Details'] === null ||
			this.props.errors.errors['Contact Details'].length > 0
		)
			isValid = false;
		this.props.errors.errors['Field Details'].forEach((f) => {
			if (f === null || f.length > 0) isValid = false;
		});
		if (
			this.props.errors.errors['Submit Details'] === null ||
			this.props.errors.errors['Submit Details'].length > 0
		)
			isValid = false;

		return isValid;
	};

	saveClaim = async () => {
		await this.props.submitClaimForm(this.state);
		let isValid = this.validateClaim();
		if (isValid) {
			this.setState({ showSpinner: true }, async () => {
				let saveData = await this.props.saveClaim(this.props.state);
				if (saveData.error) {
					//Triggers errorHandler, which looks for the event with .error property!
					this.props.submitClaimForm({ ...this.state, ...saveData });
					this.setState({ showSpinner: false });
				} else {
					let pdfRes = await this.props.generatePDF({
						...this.props.state,
						referenceNum: saveData.referenceNumber,
					});
					if (pdfRes.error) {
						//Triggers errorHandler, which looks for the event with .error property!
						this.props.submitClaimForm({
							...this.state,
							...pdfRes,
						});
						this.setState({ showSpinner: false });
					} else {
						saveData['pdfID'] = pdfRes.files[0].ID;
						saveData[
							'usertype'
						] = this.props.state.contact.usertype;
						let emailCheck = await this.props.sendEmails({
							...saveData,
						});
						if (emailCheck.error) {
							//Triggers errorHandler, which looks for the event with .error property!
							this.props.submitClaimForm({
								...this.state,
								...emailCheck,
							});
							this.setState({ showSpinner: false });
						} else {
							this.setState({ showSpinner: false }, () => {
								this.props.history.push('/successPage');
							});
						}
					}
				}
			});
		} else {
			this.setState({ showModal: true });
		}
	};

	render() {
		return (
			<React.Fragment>
				{this.state.showSpinner && <Spinner />}
				{this.state.showModal && (
					<Modal
						title='Claim Validation Error'
						text='Please go back and ensure that all of the fields are filled out properly.'
						confirm={() => this.setState({ showModal: false })}
					/>
				)}
				<div className='submitClaimContainer'>
					<h2>Submit Claim</h2>
					<div className='checkBoxContainer'>
						<CheckBox
							name={'consent'}
							checked={this.props.state.submit.consent}
							handleChecked={this.handleChange}
							text=''
						/>
						<p>
							I agree that the claims are truthful, consent to
							have documents checked and that fields may be
							subject to a random audit.
						</p>
					</div>
					
					<p>Once your claim has been submitted you will receive a unique reference number, if you do not receive this number please contact <a
							href='mailto: customer-support-uk@agconnect.org'
							target='_blank'
							className='success-support-email'
						>customer-support-uk@agconnect.org</a>.</p>	


					{/* <div className='checkBoxContainer'>
						<CheckBox
							name={'marketing'}
							checked={this.props.state.submit.marketing}
							handleChecked={this.handleChange}
							text=''
						/>
						<p>
							I would like to subscribe to the email newsletter
							from BASF plc to receive in-season updates and
							product information. BASIS CPD points are also
							available. I can withdraw this consent at any time
							or update my preferences by visiting{' '}
							<a
								href='https://www.basfrealresults.co.uk'
								style={{ color: '#f19425' }}
								target='_blank'
								rel='noopener noreferrer'
							>
								www.basfrealresults.co.uk.
							</a>
						</p>
					</div> */}
				</div>
				{this.props.errors.errors &&
				this.props.errors.errors['Submit Details'] ? (
					<ErrorMsgPanel
						errors={this.props.errors.errors['Submit Details']}
					/>
				) : null}
				<Footer
					validate={() => submitValidation(this.state)}
					onClick={
						submitValidation(this.state).length === 0
							? this.saveClaim
							: () => this.props.submitClaimForm(this.state)
					}
					nextRoute={'/submitClaim'}
					backRoute={`/fieldContainer${this.props.state.fields.length}`}
					value={'Submit Claim'}
				/>
			</React.Fragment>
		);
	}
}

export default withRouter(SubmitClaim);
