import React from 'react';
import { connect } from 'react-redux'
import { doSendFiles, doDeleteFile } from '../actions/fileUploadActions';
import FileUpload from '../components/FileUpload/FileUpload';

const FileUploadContainer = (props) =>
    <FileUpload {...props} />

const mapStateToProps = state => ({
    files: state.files,
    currentFieldID: state.currentField,
})

const mapDispatchToProps = {
    sendFiles: doSendFiles,
    deleteFile: doDeleteFile,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileUploadContainer);