import React, { Component, Fragment } from 'react';
import mapOptions from './mapOptions.json';
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
} from 'react-google-maps';

class Map extends Component {
	static defaultProps = {
		// googleMapURL:
		// 	process.env.NODE_ENV === 'development'
		// 		? `https://maps.googleapis.com/maps/api/js?key=AIzaSyDwc0k_y_eqac-0xfJ3L71kq_qK-fFFAfg&libraries=geometry,drawing,places`
		// 		: `https://maps.googleapis.com/maps/api/js?key=AIzaSyCZ9N4GxkHU4mUnjHvF7xJUd4roTHM0nQ0&libraries=geometry,drawing,places`,
	};

	CMap = withScriptjs(
		withGoogleMap((props) => (
			<GoogleMap
				defaultZoom={16}
				center={{
					lat: this.props.latitude || 51.5074,
					lng: this.props.longitude || -0.118092,
				}}
				mapTypeId={'hybrid'}
				styles={mapOptions}
				onClick={(e) => this.props.handleMarkerDrag(e.latLng)}
			>
				{props.children}
			</GoogleMap>
		))
	);

	getDragPosition = (e) => {
		this.props.handleMarkerDrag(e.latLng);
	};

	render() {
		return (
			<Fragment>
				<this.CMap
					// googleMapURL={this.props.googleMapURL}
					googleMapURL={window.google}
					loadingElement={<div style={{ height: `100%` }} />}
					containerElement={<div style={{ height: `500px` }} />}
					mapElement={<div style={{ height: `100%` }} />}
					center={{
						lat: this.props.latitude || 51.5074,
						lng: this.props.longitude || -0.118092,
					}}
				>
					<Marker
						position={{
							lat: this.props.latitude || 51.5074,
							lng: this.props.longitude || -0.118092,
						}}
						draggable={true}
						onDragEnd={this.getDragPosition}
					/>
				</this.CMap>
			</Fragment>
		);
	}
}

export default Map;
