// Add Event validation checks here and append results to the object returned by validateClaimInputs
import { contactValidation } from './contactValidation';
import { fieldValidation } from './fieldValidation';
import { submitValidation } from './submitValidation';
import { UPDATE_CONTACT_INFO, UPDATE_FIELD_INFO, SUBMIT_CLAIM_FORM } from '../constants/actionTypes';
import store from '../store';

export const validateClaimInputs = async (event, type) => {

    const currentState = store.getState();
    let newEvent = {...event}

    if (type === UPDATE_FIELD_INFO) {
        let files = store.getState().files.filter(f => store.getState().fields[newEvent.fieldIndex].files.includes(f.ID));
        newEvent.files = files.map(f => { return {id: f.ID, type: f.documentType}});
    }

    let contactErrors = currentState.errors.errors['Contact Details'];
    let fieldErrors = currentState.errors.errors['Field Details'];
    let submitErrors = currentState.errors.errors['Submit Details'];

    switch (type) {
        case UPDATE_CONTACT_INFO:
            contactErrors = contactValidation(newEvent);
            break;
        case UPDATE_FIELD_INFO:
            fieldErrors[event.fieldIndex] = fieldValidation(newEvent);
            break;
        case SUBMIT_CLAIM_FORM:
            submitErrors = submitValidation(newEvent);
            break;
        default: return;
    }

	return {
        "Contact Details": contactErrors,
        "Field Details": fieldErrors,
        "Submit Details": submitErrors
	};
};