import { SAVE_CLAIM_FORM } from '../constants/actionTypes';

const initalState = {}

const saveReducer = (state = initalState, action) => {
    switch (action.type) {
        case SAVE_CLAIM_FORM: return action.data;
        default: return state;
    }
}

export default saveReducer;