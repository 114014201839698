export const contactValidation = (event) => {
	let contactErrors = [];
	contactErrors.push(validateDistributor('Distributor', event.distributor));
	contactErrors.push(validateProduct('Seed purchased', event.product));
	contactErrors.push(
		validateEmptyString('Farmer Details: First Name', event.f_firstname)
	);
	contactErrors.push(
		validateEmptyString('Farmer Details: Last Name', event.f_lastname)
	);
	contactErrors.push(
		validateEmptyString(
			'Farmer Details: Farm Business Name',
			event.f_farmname
		)
	);
	event.distributor.Name !== 'Agrii' &&
		contactErrors.push(
			validateEmptyString(
				'Farmer Details: Cheque Payable To',
				event.f_payableto
			)
		);
	contactErrors.push(
		validateEmptyString(
			'Farmer Details: House Name / Number ',
			event.f_address1
		)
	);
	contactErrors.push(
		validateEmptyString('Farmer Details: Town ', event.f_address2)
	);
	contactErrors.push(
		validateEmptyString('Farmer Details: County', event.f_county)
	);
	contactErrors.push(
		validatePostCode('Farmer Details: PostCode', event.f_postcode)
	);
	contactErrors.push(validateEmail('Farmer Details: Email', event.f_email));
	contactErrors.push(
		validatePhone('Farmer Details: Phone Number', event.f_phone)
	);
	contactErrors.push(
		validateCheckbox(
			'Farmer Details: Address Confirm',
			event.f_addressConfirm
		)
	);

	// if ((event.claimtype === "InVigor" && event.f_includeagronomist)
	// || (event.claimtype === "Herbicide" && event.usertype === "Agronomist")
	// || (event.claimtype === "Herbicide" && event.usertype === "Farmer" && !event.f_selfadvised)) {
	if (
		event.usertype === 'Agronomist' ||
		(event.usertype === 'Farmer' && !event.f_selfadvised) ||
		event.usertype === 'AgriiContact'
	) {
		contactErrors.push(
			validateEmptyString(
				'Agronomist Details: First Name',
				event.a_firstname
			)
		);
		contactErrors.push(
			validateEmptyString(
				'Agronomist Details: Last Name',
				event.a_lastname
			)
		);
		// contactErrors.push(
		// 	validateEmptyString(
		// 		'Agronomist Details: Address Line 1',
		// 		event.a_address1
		// 	)
		// );
		// contactErrors.push(
		// 	validateEmptyString('Agronomist Details: County', event.a_county)
		// );
		// contactErrors.push(
		// 	validatePostCode('Agronomist Details: PostCode', event.a_postcode)
		// );
		contactErrors.push(
			validateEmail('Agronomist Details: Email', event.a_email)
		);
		// contactErrors.push(
		// 	validatePhone('Agronomist Details: Phone Number', event.a_phone)
		// );
	}

	if (event.usertype === 'AgriiContact') {
		contactErrors.push(
			validateEmptyString(
				'Agrii Contact Details: First Name',
				event.ac_firstname
			)
		);
		contactErrors.push(
			validateEmptyString(
				'Agrii Contact Details: Last Name',
				event.ac_lastname
			)
		);
		contactErrors.push(
			validateEmail('Agrii Contact Details: Email', event.ac_email)
		);
	}

	return contactErrors.filter((d) => d);
};

const validateEmptyString = (name, string) => {
	if (!string || string === '') {
		return `${name} is a required field.`;
	}
};

const validateDistributor = (name, string) => {
	if (!string || string === '' || string === 'Select Distributor *') {
		return `${name} is a required field.`;
	}
};

const validateProduct = (name, string) => {
	if (!string || string === '' || string.length === 0) {
		return `${name} is a required field.`;
	}
	if (string.includes('InV1035 Standard') || string.includes('InV1266CL Standard')){
		return `The deadline for claim submission of ${name} InV1035 Standard or InV1266CL Standard has passed`;
	}	
};

const validatePhone = (name, phone) => {
	// const regex = /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/;
	// const regex = /^((((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))(\s?\(\d{4}|\d{3}))?)|((\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3})|((((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\(\d{4}|\d{3}))?$/;
	const regex = /^(\s*\d\s*){10,11}$/;
	if (!phone || phone === '') {
		return `${name} is a required field.`;
		// } else if (phone.match('[A-Za-z]+') !== null) {
	} else if (!regex.test(phone)) {
		return `${name} is not in a valid format.`;
	}
};

const validateEmail = (name, email) => {
	// eslint-disable-next-line
	const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	if (!email || email === '') {
		return `${name} is a required field.`;
	} else if (!regex.test(email.toLowerCase())) {
		return `${name} is not in a valid format.`;
	}
};

const validatePostCode = (name, postCode) => {
	/*
        Regex by Jon
        ^([A-Za-z](([0-9]([0-9]|[A-Za-z])?)|([A-Za-z][0-9]([0-9]|[A-Za-z])?)) ?[0-9][A-Za-z]{2})$
        Allowed formats (A=Alpha, N=Numeric):
        AN NAA
        ANN NAA
        AAN NAA
        AANN NAA
        ANA NAA
        AANA NAA
    */
	const regex =
		'^([A-Za-z](([0-9]([0-9]|[A-Za-z])?)|([A-Za-z][0-9]([0-9]|[A-Za-z])?)) ?[0-9][A-Za-z]{2})$';

	if (!postCode || postCode === '') {
		return `${name} is a required field.`;
	} else if (
		postCode.match(regex) === null ||
		!postCode.match(regex).includes(postCode)
	) {
		return `${name} is not in a valid format.`;
	}
};

const validateCheckbox = (name, checkbox) => {
	if (!checkbox) {
		return `${name} is a required field.`;
	}
};
