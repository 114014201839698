import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { updateContactInfo } from '../actions/contactActions';
import { fetchConfigIfNeeded } from '../actions/configActions';
import ContactInformation from '../components/ContactInformation/ContactInformation';

const ContactContainer = (props) => {
	const { fetchConfigs, home } = props;
	useEffect(() => {
		fetchConfigs();
	}, [home, fetchConfigs]);
	return <ContactInformation {...props} />;
};

const mapStateToProps = (state) => ({
	// contact: state.contact,
	// config: state.config,
	home: state.home,
	errors: state.errors,
});

const mapDispatchToProps = {
	updateContactInfo: updateContactInfo,
	fetchConfigs: fetchConfigIfNeeded,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactContainer);
