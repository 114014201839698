import {
	REQUEST_CONFIG_DATA,
	RECIEVE_CONFIG_DATA,
} from '../constants/actionTypes';

const initalState = {
	claimTypes: [],
	distributors: [],
	products: [],
	reasons_failed: [],
	redrilling_options: [],
	redrilling_types: [],
	isLoaded: false,
	isLoading: false,
};

const configReducer = (state = initalState, action) => {
	switch (action.type) {
		case REQUEST_CONFIG_DATA:
			return { ...state, isLoading: true };
		case RECIEVE_CONFIG_DATA:
			return { ...action.data, isLoading: false, isLoaded: true };
		default:
			return state;
	}
};

export default configReducer;
