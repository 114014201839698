import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import './NavBar.scss';
import xbutton from '../../assets/images/common/close.png';
import Complete from '../../assets/images/common/complete.png';
import Incomplete from '../../assets/images/common/incomplete.png';

class NavBar extends Component {
	constructor(props) {
        super(props);
        this.state = {
			windowWidth: window.innerWidth,
            showX: false, 
        };
    }

    componentDidMount = () => {
		window.onscroll = function () {
			stickyNav()
        };
		var header = document.getElementById("navBar");  

		function stickyNav() {
			if (window.pageYOffset >= 139) {
				header.classList.add("sticky");
			} else {
				header.classList.remove("sticky");
			}
		}
   }  

    hanldeClickNav = () => { 
        var x = document.getElementById("mobileNavItemsContainer");
        x.classList.toggle('collapsed'); 
        this.setState({showX: !this.state.showX})
    }

    isError = (details, index = -1) => {
        if ((index === -1 && this.props.errors.errors[details] === null) || (index !== -1 && this.props.errors.errors[details][index] === null) || (index !== -1 && this.props.errors.errors[details][index] === undefined)) {
            return null;
        }
        else if ((index === -1 && this.props.errors.errors[details].length === 0) || (index !== -1 && this.props.errors.errors[details][index].length === 0)) {
            return <img className='completeness' alt='Complete' src={Complete} />;
        }

        return <img className='completeness' alt='Incomplete' src={Incomplete} />;
    }
    
	render() {
        const dynamicFields = () => {
            let fieldBars = [];

            this.props.fields.forEach(function (field, index) {
                    let currentRoute = "/fieldContainer"+(index+1);
                    let routeLabel = '';
                    if (index !== 0) {
                        routeLabel = index + 1;
                    }
                    let barLabel = (field.fieldname === "" ? ('Field Information ' + routeLabel) : field.fieldname);
                    let activeCheck = (match, location) => {
                        if (location.pathname === currentRoute) {
                            return true;
                        } else {
                            return false;
                        }
                    };
                    //Build the side nav that points to the route 
                    fieldBars.push(<NavLink className="noselect navItem" key={index} to={`/fieldContainer${index+1}`} onClick={(event) => event.preventDefault()} isActive={activeCheck}>{barLabel}{this.isError('Field Details', index)}</NavLink>);
            }, this);
            return fieldBars;
        };

        const navBar = ( 
            <div id="navItemsContainer">
                <NavLink id="home" className="noselect navItem" exact onClick={(event) => event.preventDefault()} to={"/contactContainer"}>Contact Information{this.isError('Contact Details')}</NavLink>
                {dynamicFields()}
                <NavLink className="noselect navItem" onClick={(event) => event.preventDefault()} to={"/submitClaim"}>Submit Claim{this.isError('Submit Details')}</NavLink>
            </div> 
        );
        
        const mobileNav = (
            <div className="mobile-bars" onClick={this.hanldeClickNav}>
                {this.state.showX
                ? <div className="close-bars noselect"><img src={xbutton} alt="" /></div>
                : <div className="bars" />
                }
                <div id="mobileNavItemsContainer" className="collapsed">
                    <NavLink id="home" className="noselect" exact to={"/contactContainer"}>Contact Information{this.isError('Contact Details')}</NavLink>
                    {dynamicFields()}
                    <NavLink className="noselect" to={"/submitClaim"}>Submit Claim{this.isError('Submit Details')}</NavLink>
                </div> 
            </div>
        )
        return (
            this.props.home !== "" 
            ?
            <ul id='navBar' className="navBar">
                {mobileNav}
                {navBar}
            </ul>  
            :    
            <ul id='navBar' className="Hidden"></ul>   
        );
    }
}



export default NavBar;