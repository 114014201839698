import { UPDATE_FIELD_INFO, ADD_FIELD_INFO, UPDATE_CURRENT_FIELD, DELETE_FIELD_INFO } from '../constants/actionTypes';
import { fieldInformationModel } from '../constants/fieldInformationModel';

export function updateFieldInfo(data) {
    return {
        type: UPDATE_FIELD_INFO,
        data
    }
}

export function addFieldInfo() {
    return {
        type: ADD_FIELD_INFO,
        data: fieldInformationModel
    }
}

export function removeFieldInfo(data) {
    return {
        type: DELETE_FIELD_INFO,
        data
    }
}

export function updateCurrentField(data) {
    return {
        type: UPDATE_CURRENT_FIELD,
        data
    }
}