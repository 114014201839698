import { SUBMIT_CLAIM_FORM } from '../constants/actionTypes';

const initalState = {
    consent: false,
    marketing: false,
}

const submitReducer = (state = initalState, action) => {
    switch (action.type) {
        case SUBMIT_CLAIM_FORM:
            //.error is used for error validating on backend calls on submit, but i never want this to actually be part of the redux store
            delete action.data.error;
            return Object.assign({}, state, action.data);
        default: return state;
    }
}

export default submitReducer;