import { UPDATE_ERRORS, REMOVE_VALIDATION_ERRORS, CLAIM_VALIDATION_ERROR, CLEAR_STORE, DELETE_FIELD_ERRORS } from '../constants/actionTypes';

const initialState = {
    errors: {
        'Contact Details': null,
        'Field Details': [],
        'Submit Details': null
    }
}

function onClaimValidationError(state, action) {
    return {
        ...state,
        errors: {...state.errors, ...action.errors}
    };
}

const errorsReducer = (state = initialState, action) => {
    let newState = {...state};
    switch (action.type) {
        case UPDATE_ERRORS:
            return action.payload;

        case DELETE_FIELD_ERRORS:
            if (newState.errors['Field Details'].length === action.payload.fieldCount) {
                return {
                    errors: {
                        'Contact Details': newState.errors['Contact Details'],
                        'Submit Details': newState.errors['Submit Details'],
                        'Field Details': newState.errors['Field Details'].slice(0, action.payload.index).concat(newState.errors['Field Details'].slice(action.payload.index+1, newState.errors['Field Details'].length))
                    }
                }
            }
            return newState;
        case REMOVE_VALIDATION_ERRORS:
            newState[action.payload] = [];
            return newState;

        case CLAIM_VALIDATION_ERROR:
            return onClaimValidationError(state, action);
        case CLEAR_STORE:
            return {errors: {
                'Contact Details': null,
                'Field Details': [],
                'Submit Details': null
            }};
        default:
            return state;
    }
}

export default errorsReducer;