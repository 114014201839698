import React from 'react';
import './ErrorMsgPanel.scss';


function generateErrors(errors) {
	let errorItems = [];

	errors.forEach((error, i) => {
		errorItems.push(<div className="errorLine" key={i}>{error}</div>);
	});
	return errorItems;
}

const ErrorMsgPanel = props =>
	<div className='error-panel'>
		{generateErrors(props.errors)}
	</div>


export default ErrorMsgPanel;