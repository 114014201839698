import { SEND_EMAILS } from '../constants/actionTypes';

const initalState = {}

const pdfReducer = (state = initalState, action) => {
    switch (action.type) {
        case SEND_EMAILS: return action.data;        
        default: return state;
    }
}

export default pdfReducer;