import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { updateFieldInfo, addFieldInfo, updateCurrentField, removeFieldInfo } from '../actions/fieldActions';
import { deleteFieldErrors } from '../actions/errorsActions';
import FieldInformation from '../components/FieldInformation/FieldInformation';

const FieldRoutesContainer = props => 
    <Switch>
        {props.fields.map((_, i) => <Route key={i} exact path={"/fieldContainer"+(i+1)} render={() => <FieldInformation key={i} {...props} />} />)}
    </Switch>

const mapStateToProps = state => ({
    fields: state.fields,
    field: state.fields[state.currentField], 
    currentField: state.currentField,
    config: state.config,
    home: state.home,
    errors: state.errors,
    contact: state.contact,
})

const mapDispatchToProps = dispatch => ({
    updateFieldInfo: (data) => dispatch(updateFieldInfo(data)),
    addFieldInfo: () => dispatch(addFieldInfo()),
    removeFieldInfo: (data) => dispatch(removeFieldInfo(data)),
    updateCurrentField: (data) => dispatch(updateCurrentField(data)),
    deleteFieldErrors: (data) => dispatch(deleteFieldErrors(data))
})

export default withRouter(connect(
    mapStateToProps, 
    mapDispatchToProps
)(FieldRoutesContainer));