import React, { Component } from 'react';
import './HomePage.scss';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import herbicidePDF from '../../assets/files/OSR Agrii Kat Turbo Herbicide 2023 T&Cs.pdf';
//import seedPDF from '../../assets/files/Winter_OSR_Establishment_Risk_Share_Programme_2022.pdf';
//import seedPDFAgrii from '../../assets/files/Agrii WOSR Premium Establishment Risk Share Programme 2022.pdf';
//import inV1035PDF from '../../assets/files/OSR_InV1035_Premium_Risk_Share_2020_T&Cs.pdf';
//import inVigor2019PDF from '../../assets/files/InV1035_terms_2019_Premium_Risk_Share.pdf';
import Script from 'react-load-script';
// import inVigorPDF from '../../assets/files/inVigor_terms_2020.pdf';

// const DISTRIBUTORS = [
// 	{ id: 1, label: 'ADM' },
// 	{ id: 2, label: 'Agrii' },
// ];
class HomePage extends Component {
	constructor() {
		super();
		this.state = {
			time: new Date().getTime(),
			showChoice: false,
			selectedDist: {},
			claimError: false,
		};
		this.timer = null;
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.timer = setInterval(() => {
			this.setState({ time: new Date().getTime() });
		}, 1000);
	}

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	handleChange = (e) => {
		if (e.target.value.Name === 'Agrii') {
			this.setState({
				showChoice: true,
				selectedDist: e.target.value,
				claimError: false,
			});
		} else {
			this.setState({
				selectedDist: e.target.value,
				claimError: false,
			});
		}
	};

	createClaim = () => {
		if (Object.keys(this.state.selectedDist).length > 0) {
			this.props.updateRiskShare('Seed');
			this.props.updateDistributor(this.state.selectedDist);
			this.props.history.push('/contactContainer');
		} else {
			this.setState({ claimError: true });
		}
	};

	render() {
		const error = this.state.claimError
			? `claimError show`
			: `claimError hidden`;
		const googleMapURL =
			process.env.NODE_ENV === 'development'
				? `https://maps.googleapis.com/maps/api/js?key=AIzaSyDwc0k_y_eqac-0xfJ3L71kq_qK-fFFAfg&libraries=geometry,drawing,places`
				: `https://maps.googleapis.com/maps/api/js?key=AIzaSyCZ9N4GxkHU4mUnjHvF7xJUd4roTHM0nQ0&libraries=geometry,drawing,places`;
		return (
			<React.Fragment>
				<Script url={googleMapURL} />
				<div className='homeContainer'>
					<div className='container'>
						{!this.state.showChoice && this.props.distributors ? (
							<div className='distributorChoice'>
								<div className='container'>
									
									<h2 className='grey justify-center'>
										Where did you purchase the product?
									</h2>
									<div className='dropdownContainer justify-center'>
										
										<p className='fieldHeader'>
											Seed Seller / Distributor
										</p>
										<DropDownList
											data={this.props.distributors}
											dataItemKey='distributorID'
											textField='Name'
											onChange={this.handleChange}
											name='distributors'
										/>
										<p className='requiredField'>
											Required
										</p>
										
									</div>
									<div className={error}>
										<p>
											Please select a seed seller /
											distributor above.
										</p>
									</div>
									<div className='homeBtnContainer'>
										<div
											className='homeBtn'
											onClick={this.createClaim}
										>
											Create Claim
										</div>
									{/*<p style={{textAlign:'center', margin: '30px 0', fontWeight:'bold'}}>The deadline for claims submission has passed</p>*/}
									</div>
								</div>
							</div>
						) : (
							<div className='choiceContainer'>
								<div className='homeTitle'>
									Which Risk share programme do you wish to
									make a claim?
								</div>
								<div className='homeBtnContainer'>
									
									<NavLink to='/contactContainer'>
										<div
											className='homeBtn'
											onClick={() => {
												this.props.updateRiskShare(
													'Herbicide'
												);
												this.props.updateDistributor(
													this.state.selectedDist
												);
											}}
										>
											Herbicide Risk Share
										</div>
										</NavLink>									
										<div className='or'>OR</div>{' '}
										{(this.state.selectedDist.Name === 'Agrii'  && window.location.pathname.includes("/premium"))}										
									<NavLink to='/contactContainer'>
										<div
											className='homeBtn'
											onClick={() => {
												this.props.updateRiskShare(
													'Seed'
												);
												this.props.updateDistributor(
													this.state.selectedDist
												);
											}}
										>
											Seed Risk Share
										</div>
										</NavLink> {/*: ''*/}
									</div>
								{/*<p style={{textAlign:'center', margin: '30px 0'}}>The deadline for <strong>Herbicide Risk Share</strong> claims submission has passed</p> */}
								{/*(!window.location.pathname.includes("/premium")? 
									<p style={{textAlign:'center', margin: '30px 0', fontWeight:'bold'}}>The deadline for claims submission has passed</p>:"")*/}
								{/*<p style={{textAlign:'center', margin: '30px 0'}}>The deadline for claims submission has passed</p> */}

								<div className='startOverContainer'>
									<div
										className='startOver'
										onClick={() => {
											this.setState({
												showChoice: false,
											});
										}}
									>
										Start Over
									</div>
								</div>
							</div>
						)}
					</div>

					<div className='termsContainer'>
						<div className='container'>
							<p style={{ fontSize: 12 }}>
								Once your claim has been submitted you will
								receive a unique reference number, if you do not
								receive this number please contact{' '}
								<a
									href='mailto: customer-support-uk@agconnect.org'
									target='_blank'
									className='success-support-email'
									style={{ display: 'inline-block' }}
								>
									customer-support-uk@agconnect.org
								</a>
								.
							</p>
							{/*<h5>BASF SE 2023 Terms & Conditions</h5>*/}
							{/*<div className='seedContainer'>
								<h5>Seed</h5>
								<ul>
									<li>
										All purchases of any InVigor seed
										variety will be automatically registered
										into the programme
									</li>
									<li>
										The full claim, complete with all
										required evidence, must be submitted
										before 23:59 on 31st October 2022.
										Submissions made after this deadline
										will not be valid. Applications which do
										not provide the necessary evidence will
										not qualify for a reimbursement.
									</li>
									<li>
										On or before 22nd September 2022 plant
										the InVigor WOSR seed according to the
										field conditions, at a minimum sowing
										rate of 40 viable seeds per square metre
										and follow good agricultural practice.
									</li>
									<li>
										Failed fields must be re-drilled with
										any crop apart from WOSR or left fallow
										(with any remaining crop removed) to
										qualify for the offer.
									</li>
									<li>
										Successful applications will be
										reimbursed £30/ha, with a minimum claim
										area of 3ha.
									</li>
								</ul>
							</div>
							<div className='termsBtnContainer'>
								<a
									href={seedPDF}
									target='_blank'
									rel='noopener noreferrer'
								>
									<div className='termsBtn'>
										See full terms & conditions for standard
										claims
									</div>
								</a>
							</div>
							{
							(this.state.selectedDist.Name === 'Agrii'  && window.location.pathname.includes("/premium"))
							? (
								<div className='termsBtnContainer'>
									<a
										href={seedPDFAgrii}
										target='_blank'
										rel='noopener noreferrer'
									>
										<div className='termsBtn'>
											See full terms & conditions for premium claims
										</div>
									</a>
								</div>
							) : null
							}
							
							{/*this.state.showChoice ? (
								<div className='termsBtnContainer'>
									<a
										href={inVigor2019PDF}
										target='_blank'
										rel='noopener noreferrer'
									>
										<div className='termsBtn'>
											See full terms & conditions for 2019
											InV1035 premium
										</div>
									</a>
								</div>
							) : null*/}
							{/*this.state.showChoice ? (
								<div className='termsBtnContainer'>
									<a
										href={inV1035PDF}
										target='_blank'
										rel='noopener noreferrer'
									>
										<div className='termsBtn'>
											See full terms & conditions for 2022
											InV1035 premium
										</div>
									</a>
								</div>
							) : null*/}
							{this.state.showChoice ? (
								<div className='herbicideContainer'>
									{/*<h5>Herbicide</h5>*/}
									<ul>
										<li>
											All purchases of Winter OSR
											Herbicide Kat Turbo will be
											automatically registered into the
											programme.
										</li>
										<li>
											The full claim, complete with all
											required evidence, must be submitted
											before 23:59 on 31st October 2023.
											Submissions made after this deadline
											will not be valid. Applications
											which do not provide the necessary
											evidence will not qualify for a
											reimbursement.
										</li>
										{/*<li>
											On or before 22nd September 2023
											plant the WOSR seed according to the
											field conditions and follow good
											agricultural practice.
										</li>*/}
										<li>
											Failed fields must be re-drilled
											with any crop apart from WOSR or
											left fallow (with any remaining crop
											removed) to qualify for the offer.
										</li>
										<li>
											Successful applications will be
											reimbursed up to £50/ha, with a
											minimum claim area of 3ha.
										</li>
									</ul>
								</div>
							) : null}
							{this.state.showChoice ? (
								<React.Fragment>
									<div className='termsBtnContainer'>
										<a
											href={herbicidePDF}
											target='_blank'
											rel='noopener noreferrer'
										>
											<div className='termsBtn'>
												See full terms & conditions for
												Herbicide Kat Turbo
											</div>
										</a>
									</div>
								</React.Fragment>
							) : null}
						</div>

						<div className='hr'></div>
						<h5>Always read and follow label directions.</h5>
						<div className='copyRightText'>© BASF SE 2023</div>
						<div className='bottomTermsContainer'>
							<a
								href='https://www.agricentre.basf.co.uk/en/Disclaimer.html'
								target='_blank'
								rel='noopener noreferrer'
								className='bottomTermsItem'
							>
								Disclaimer
							</a>
							<a
								href='https://www.agricentre.basf.co.uk/en/Data-Privacy.html'
								target='_blank'
								rel='noopener noreferrer'
								className='bottomTermsItem'
							>
								Privacy & Data Protection
							</a>
							<a
								href='https://www.agricentre.basf.co.uk/en/Credits.html'
								target='_blank'
								rel='noopener noreferrer'
								className='bottomTermsItem'
							>
								Credits
							</a>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		distributors: state.config.distributors,
	};
};

export default withRouter(connect(mapStateToProps)(HomePage));
