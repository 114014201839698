import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { updateRiskShare } from '../actions/homeActions';
import { updateDistributor } from '../actions/contactActions';
import { fetchConfigIfNeeded } from '../actions/configActions';
import HomePage from '../components/HomePage/HomePage';

const HomeContainer = (props) => {
	const { fetchConfigs, home } = props;
	useEffect(() => {
		fetchConfigs();
	}, [home, fetchConfigs]);
	return (
		<HomePage
			updateRiskShare={props.updateRiskShare}
			updateDistributor={props.updateDistributor}
		/>
	);
};

const mapStateToProps = (state) => ({
	contact: state.contact,
	home: state.home,
});

const mapDispatchToProps = (dispatch) => ({
	updateRiskShare: (data) => dispatch(updateRiskShare(data)),
	fetchConfigs: () => dispatch(fetchConfigIfNeeded()),
	updateDistributor: (dist) => dispatch(updateDistributor(dist)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
