import React from 'react';
import './Modal.scss';

const Modal = props => {
    return (
        <React.Fragment>
            <div className='Modal-Backdrop' />
            <div className='Modal'>
                <div className='Modal-Title'>{props.title}</div>
                <div className='Modal-Text'>{props.text}</div>
                <div className='Modal-Buttons'>
                    {props.cancel !== undefined && <button type='button' className='btnCancel' onClick={props.cancel}>Cancel</button>}
                    {props.confirm !== undefined && <button type='button' className='btnOkay' onClick={props.confirm}>Okay</button>}
                </div>
            </div>
        </React.Fragment>
    )
}

export default Modal;