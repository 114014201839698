import { SEND_EMAILS } from '../constants/actionTypes';
import axios from 'axios';
import { baseURL } from '../constants/api';

export function sendEmails(data) {
    return async (dispatch) => {
            let url = baseURL + '/sendEmails';
            let postData = data || {};
            try {
                let response = await axios.post(url, postData);
                delete response.data.status;
                return dispatch(
                    {
                        type: SEND_EMAILS,
                        data: response.data
                    }
                );
            } 
            catch(error) {
                return {'error': 'There was an error sending emails.'}
            }
    }
}
